import React, { useEffect, useState } from "react";
import { Carousel } from "rsuite";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";

function NationlNews() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 2,
    },
  };

  const [AllBanner, setAllBanner] = useState([]);

  useEffect(() => {
    AllBannerList();
  }, []);

  const AllBannerList = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getBanner");
    if (res.status === 200) {
      // console.log(res);
      const data = res.data.BannerInfo.filter((item)=>item.Status == "Active")
      setAllBanner(data);
    }
  };

  return (
    <>
      <div className="">
        {/* <figcaption>
        <img src="/image/pmkvy.png" alt="" style={{width:"70px",height:"60px"}} />
      </figcaption> */}
      </div>
      <div className="cars mt-2 mb-2">
        <Carousel autoplay className="custom-slider">
          {AllBanner.map((item) => {
            return (
              <a href={item.BannerLink}>
                <img
                  src={`https://maargdarshak.in/Banner/${item.BannerImage}`}
                  // height="250"
                  style={{ width: "100%", height: "270px" }}
                />
              </a>
            );
          })}
          {/* <img src="/image/banner-4.jpg" height="250" />
          <img src="/image/banner-5.jpeg" height="250" />
          <img src="/image/banner2.jpg" height="250" /> */}
        </Carousel>
        {/* <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
          Education & Employment
        </h5>
        <div className="wraper-0  pb-2">
 
          <OwlCarousel
            className="owl-theme"
            loop
            margin={7}
            autoplay={false}
            dots={false}
            nav={false}
            items={2}
            responsive={responsive}
          >
            <div class="item">
              <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
                PMKVY
              </h5>

              <a
                href={user ? "https://www.pmkvyofficial.org/" : null}
                style={{ color: "black" }}
              >
                <div className="list-3">
                  <div className="list-30">
                    <div className="">
                      <figcaption>
                        <img
                          src="/image/pmkvy1.jpg"
                          alt="free"
                          style={{ width: "100%", height: "151px" }}
                        />
                      </figcaption>
                    </div>
                    <div className="entry-title" id="erd">
                      <h5>PMKVY</h5>
                      <p>
                        Pradhan Mantri Kaushal Vikas Yojana (PMKVY) is the
                        flagship scheme of the Ministry of Skill Development and
                        Entrepreneurship (MSDE) implemented by National Skill
                        Development Corporation (NSDC). The objective of this
                        Skill Certification scheme is to enable Indian youth to
                        take up industry relevant skill training that will help
                        them in securing a better livelihood.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="item">
              <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
                National Carrier Service
              </h5>
              <a
                href={user ? "https://www.ncs.gov.in/" : null}
                style={{ color: "black" }}
              >
                <div className="list-3">
                  <div className="list-30">
                    <div className="">
                      <figcaption>
                        <img
                          src="/image/NCS-Banner3.jpg"
                          alt="free"
                          style={{ width: "100%", height: "151px" }}
                        />
                      </figcaption>
                    </div>
                    <div className="entry-title">
                      <h5>National Carrier Service</h5>
                      <p>
                        ​​​​​​​​National Career Service is a Five Year Mission
                        Mode Project launched by the Hon’ble Prime Minister on
                        20th July, 2015. The project is being implemented by the
                        Directorate General of Employment, Ministry of Labour &
                        Employment. National Career Service (NCS) is a one-stop
                        solution that provides a wide array of employment and
                        career related services to the citizens of India. It
                        works towards bridging the gap between jobseekers and
                        employers, candidates seeking training and career
                        guidance, agencies providing training and career
                        counselling.The portal is also supported by a multi
                        lingual call center where the users can call the NCS
                        Toll Free Number 1514 for any support. The services of
                        the call center are available from Tuesday to Sunday
                        from 8:00 AM to 8:00 PM in seven different languages i.e
                        Hindi, English, Bengali, Kannada, Malayalam, Tamil and
                        Telugu. The services on the NCS Portal are delivered
                        through multiple channels like Computers/Mobile
                        browsers, Common Service Centers (1 lakh + centers),
                        Career Centers (900+ Employment Exchanges, 100+ Model
                        Career Centers) and Post Offices. NCS does not charge
                        any fees for registration on the portal and its
                        services.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="item">
              <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
                Department of Higher Education
              </h5>
              <a
                href={user ? "https://www.ncs.gov.in/" : null}
                style={{ color: "black" }}
              >
                <div className="list-3">
                  <div className="list-30">
                    <div className="">
                      <figcaption>
                        <img
                          src="/image/NCS-Banner3.jpg"
                          alt="free"
                          style={{ width: "100%", height: "151px" }}
                        />
                      </figcaption>
                    </div>
                    <div className="entry-title">
                      <h5>Higher Education</h5>
                      <p>
                        One of the major objectives of the Government is to
                        ensure that no student is denied the opportunity to
                        pursue higher education because she or he is poor. To
                        achieve this objective, Department of Higher Education
                        (DHE) launched schemes to provide financial assistance
                        to students who are economically weak to pursue their
                        higher education, in the form of scholarships, interest
                        subsidy on education loan and credit guarantees for
                        education loan. All categories of students in the
                        country (i.e., General, SC, ST, OBC and PwD) are
                        eligible to get financial assistance as per the
                        schematic norms. The National Education Policy (NEP)
                        2020 also envisages improvement of Gross Enrolment Ratio
                        in higher education from about 27% in 2019-20 to 50% by
                        the year 2035.
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </OwlCarousel>
        </div> */}
      </div>
    </>
  );
}

export default NationlNews;
