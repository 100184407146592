// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
} from "reactstrap";

import "./Directory.css";
import exportFromJSON from "export-from-json";
import { AiFillFileWord } from "react-icons/ai";
import { ImFolderDownload } from "react-icons/im";
import { Modal } from "react-bootstrap";
import moment from "moment";

function User() {
  const [dataSource, setdataSource] = useState([]);

  const [data, setdata] = useState([]);

  const exportType = "xls";
  const [EnterfilenameModal, setEnterfilenameModal] = useState(false);
  const [fileName, setfileName] = useState();

  const ExportToExcel = () => {
    if (fileName) {
      if (data.length != 0) {
        exportFromJSON({ data, fileName, exportType });
        setEnterfilenameModal(false);
        setfileName("");
      } else {
        alert("There is no data to export");
        setEnterfilenameModal(false);
        setfileName("");
      }
    } else {
      alert("Enter file name to export");
    }
  };

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getuser");
    if (res.status === 200) {
      setdataSource(res.data.UsersInfo);
      setdata(res.data.UsersInfo);
    }
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };

  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            USER DETAILS
          </h6>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="EloetableOrder">
              <div class="card-body">
                <div class="row" style={{ padding: "10px 20px" }}>
                  <div class="col-sm-4">
                    <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control "
                          placeholder="Search Users"
                          onChange={handleFilter}
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #ff7d26",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-8">
                    <div class="text-sm-end">
                      {" "}
                      <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#1d6f42",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                          margin: "0px 10px 0px 0px",
                        }}
                        onClick={() => {
                          setEnterfilenameModal(true);
                        }}
                      >
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          <AiFillFileWord />
                        </span>{" "}
                        EXPORT TO EXCEL
                      </button>
                      {/* <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#ff7d26",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                        }}
                        // onClick={() => {
                        //   setShow1(true);
                        // }}
                      >
                        {" "}
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          +
                        </span>{" "}
                        CATEGORY
                      </button> */}
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table
                      class="table table align-middle table-nowrap"
                      style={{ width: "96%", margin: "2%" }}
                    >
                      <thead
                        style={{ backgroundColor: "#ff7d26", color: "white" }}
                      >
                        <tr style={{ padding: "10px" }}>
                          <th
                            tabindex="0"
                            aria-label="Phone / Email sortable"
                            class="sortable"
                          >
                            USER NAME
                            <span class="order">
                              <span class="dropdown">
                                <span class="caret"></span>
                              </span>
                              <span class="dropup">
                                <span class="caret"></span>
                              </span>
                            </span>
                          </th>
                          <th>AGE</th>
                          <th
                            tabindex="0"
                            aria-label="Phone / Email sortable"
                            class="sortable"
                          >
                            PRIMARY PINCODE
                            <span class="order">
                              <span class="dropdown">
                                <span class="caret"></span>
                              </span>
                              <span class="dropup">
                                <span class="caret"></span>
                              </span>
                            </span>
                          </th>
                          <th>SECONDARY PINCODE</th>
                          <th>ADDRESS</th>
                          <th>MOBILE NO</th>
                          <th>EMAIL</th>
                          <th>ROLE</th>
                          <th>SKILL</th>
                          <th>INTEREST</th>
                          <th>NEED GUIDANCE ON</th>

                          <th>FROM DATE</th>
                          <th>TO DATE</th>
                          <th>FROM TIME</th>
                          <th>TO TIME</th>
                          <th>REGISTERED DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {search.length > 0
                          ? tableFilter.map((item) => {
                              return (
                                <tr>
                                  <td>{item.Username}</td>
                                  <td>{item.Age}</td>
                                  <td>{item.PrimaryPincode}</td>
                                  <td>
                                    {item.SecondaryPincode == "undefined"
                                      ? null
                                      : item.SecondaryPincode}
                                  </td>
                                  <td>{item.Address}</td>
                                  <td>{item.Mobile}</td>
                                  <td>{item.email}</td>
                                  <td>{item.role}</td>
                                  <td>{item.skill}</td>
                                  <td>{item.interest}</td>
                                  <td>{item.needguidance}</td>
                                  <td>
                                    {item.fromdate == "undefined"
                                      ? null
                                      : item.fromdate}
                                  </td>
                                  <td>
                                    {item.todate == "undefined"
                                      ? null
                                      : item.todate}
                                  </td>
                                  <td>
                                    {item.fromtime == "undefined"
                                      ? null
                                      : item.fromtime}
                                  </td>
                                  <td>
                                    {item.totime == "undefined"
                                      ? null
                                      : item.totime}
                                  </td>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : dataSource.map((item) => {
                              return (
                                <tr>
                                  <td>{item.Username}</td>
                                  <td>{item.Age}</td>
                                  <td>{item.PrimaryPincode}</td>
                                  <td>
                                    {item.SecondaryPincode == "undefined"
                                      ? null
                                      : item.SecondaryPincode}
                                  </td>
                                  <td>{item.Address}</td>
                                  <td>{item.Mobile}</td>
                                  <td>{item.email}</td>
                                  <td>{item.role}</td>
                                  <td>{item.skill}</td>
                                  <td>{item.interest}</td>
                                  <td>{item.needguidance}</td>
                                  <td>
                                    {item.fromdate == "undefined"
                                      ? null
                                      : item.fromdate}
                                  </td>
                                  <td>
                                    {item.todate == "undefined"
                                      ? null
                                      : item.todate}
                                  </td>
                                  <td>
                                    {item.fromtime == "undefined"
                                      ? null
                                      : item.fromtime}
                                  </td>
                                  <td>
                                    {item.totime == "undefined"
                                      ? null
                                      : item.totime}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={EnterfilenameModal}
        onHide={() => setEnterfilenameModal(false)}
        style={{ width: "320px", marginLeft: "40%", marginTop: "2%" }}
      >
        <Modal.Body>
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Excel file name ....."
                style={{
                  padding: "10px",
                  width: "82%",
                  border: "1px solid #ff7d26",
                }}
                value={fileName}
                onChange={(e) => {
                  setfileName(e.target.value);
                }}
              />
              <ImFolderDownload
                style={{
                  width: "18%",
                  color: "white",
                  backgroundColor: "#ff7d26",
                  paddingTop: "15px",
                  height: "50px",
                  paddingBottom: "15px",
                }}
                onClick={() => ExportToExcel()}
              />
            </div>
            {/* <h1 style={{ textAlign: "center", color: "#ffcc33" }}>{Count}</h1> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default User;
