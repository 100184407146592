import axios from "axios";
import React, { useState } from "react";

function DonateMoney() {
  const [username, setusername] = useState();
  const [email, setemail] = useState();
  const [number, setnumber] = useState();
  const [Enquiry, setEnquiry] = useState();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [Enquiry1, setEnquiry1] = useState();

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;

  const SendEnquiry = async (e) => {
    e.preventDefault();
    if (!username) {
      alert("Enter Username");
      return;
    }
    if (!email) {
      alert("Enter email Id");
      return;
    }
    if (!email.match(validRegex)) {
      alert("Enter Valid Email-Id");
      return;
    }
    if (!number) {
      alert("Enter Contact Number");
      return;
    }
    if (number.length != 10) {
      alert("Enter Contact Number should be 10 digits");
      return;
    }
    if (!number.match(mobilevalid)) {
      alert("Enter Valid Contact Number");
      return;
    }
    if (!Enquiry) {
      alert("Enter Enquiry Details");
      return;
    }

    try {
      const config = {
        url: "/user/addNonregisteredEnquiry",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          Username: username,
          Email: email,
          phonenumber: number,
          Enquiry: Enquiry,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Enquiry Send");
          window.location.assign("/");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const SendEnquiry1 = async (e) => {
    e.preventDefault();

    if (Enquiry1) {
      try {
        const config = {
          url: "/user/addEnquiry",
          method: "post",
          baseURL: "https://maargdarshak.in/api",
          hearder: { "content-type": "application/json" },

          data: {
            UserId: user._id,

            Enquiry: Enquiry1,
          },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            // console.log(res.data);
            alert("Enquiry Send");
            window.location.reload();
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    } else {
      alert("Please Enter Some Enquiry to Send");
    }
    e.preventDefault();
  };

  return (
    <>
      <div class="form_wrapper">
        {user ? (
          <div class="form_container">
            <div class="title_container">{/* <h2>Login Form</h2> */}</div>
            <div class="row clearfix">
              <div class="">
                <form>
                  <div class="input_field">
                    {" "}
                    <textarea
                      class="input_field-09"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                      type="text"
                      name="mobile"
                      placeholder="Discription"
                      onChange={(e) => {
                        setEnquiry1(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <input
                    class="button"
                    type="submit"
                    value="Submit"
                    onClick={(e) => SendEnquiry1(e)}
                  />
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div class="form_container">
            <div class="title_container">{/* <h2>Login Form</h2> */}</div>
            <div class="row clearfix">
              <div class="">
                <form>
                  <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-phone"></i>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Name"
                      required
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-lock"></i>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Email"
                      required
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-lock"></i>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Mobile No"
                      required
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={10}
                      onChange={(e) => {
                        setnumber(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input_field">
                    {" "}
                    <textarea
                      class="input_field-09"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                      type="text"
                      name="mobile"
                      placeholder="Discription"
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <input
                    class="button"
                    type="submit"
                    value="Submit"
                    onClick={(e) => SendEnquiry(e)}
                  />
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DonateMoney;
