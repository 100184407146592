import React from "react";

function Otp() {
  return (
    <div className="">
      <div class="form_wrapper">
        <div class="form_container">
          <div class="title_container">
            <h2>Please enter the OTP sent to your Mobile</h2>
          </div>
          <div class="row clearfix">
            <div class="">
              <div style={{display:"flex",gap:"11px"}}>
                <div class="input_field">
                  <input
                    name="otp1"
                    type="text"
                    autoComplete="off"
                    className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                   
                  />
                </div>
                <div class="input_field">
                  <input
                    name="otp1"
                    type="text"
                    autoComplete="off"
                    className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                  />
                </div>
                <div class="input_field">
                  <input
                    name="otp2"
                    type="text"
                    autoComplete="off"
                   className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                  />
                </div>
                <div class="input_field">
                  <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                   className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                  />
                </div>
                <div class="input_field">
                  <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                   className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                  />
                </div>
                <div class="input_field">
                  <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                   className="text-center otpInput"
                    tabIndex="1"
                    maxLength="1"
                    style={{padding:"unset"}}
                  />
                </div>
               
              </div>
              <input class="button" type="submit" value=" Verify OTP" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
