// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import "./Directory.css";
import ReactPaginate from "react-paginate";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

function Add_AdvertiseText() {
  const [dataSource, setdataSource] = useState([]);
  const formdata = new FormData();

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getAddText");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.AddTextInfo);
    }
  };

  const [CategoryName, setcategory] = useState();
  const [Category1Name, setCategoryImage] = useState();
  const [show, setshow] = useState(false);

  const handleClose = () => {
    setshow(false);
  };

  const [dataSource1, setdataSource1] = useState([]);

  useEffect(() => {
    AllPartners1();
  }, []);

  const AllPartners1 = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCategory");
    if (res.status === 200) {
      // console.log(res);
      setdataSource1(res.data.CategoryInfo);
    }
  };

  const AddCategory = async (e) => {
    e.preventDefault();
    if (!CategoryName) {
      alert("Please Select Type");
      return;
    }

    try {
      const config = {
        url: "/user/addAddText",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          TextDisplayType: CategoryName.toUpperCase(),
          Content: Category1Name,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("ADVERTISE-TEXT Added");
          setshow(false);
          setcategory();
          setCategoryImage();
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [EditData, setEditData] = useState({});

  const [show1, setshow1] = useState(false);

  const handleClose1 = () => {
    setshow1(false);
  };

  const [CategoryName1, setcategory1] = useState();
  const [Category1Name1, setCategoryImage1] = useState();

  const EditCategory = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/user/EditAddText",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          userId: EditData?._id,
          TextDisplayType: CategoryName1.toUpperCase(),
          Content: Category1Name1,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("ADVERTISE-TEXT Updated");
          setshow1(false);
          setcategory1();
          setCategoryImage1();
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const DeleteCategory = async (Id) => {
    try {
      const config = {
        url: "/user/deleteAddText",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { AddTextId: Id },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("ADVERTISE-TEXT Deleted");
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 0px 20px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            ADVERTISE-TEXT
          </h6>
          <button
            style={{
              backgroundColor: "#ff7d26",
              color: "white",
              fontWeight: "600",
              fontSize: "14px",
              padding: "10px 10px",
              borderRadius: "20px",
            }}
            onClick={() => setshow(true)}
          >
            +ADD TEXT
          </button>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="EloetableOrder">
              <div class="card-body">
                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table
                      class="table table align-middle table-nowrap"
                      style={{ width: "96%", margin: "2%" }}
                    >
                      <thead
                        style={{ backgroundColor: "#ff7d26", color: "white" }}
                      >
                        <tr style={{ padding: "10px", textAlign: "center" }}>
                          <th>DISPLAY-Type</th>
                          <th>TEXT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSource
                          .slice(pagesVisited, pagesVisited + usersPerPage)
                          .map((item) => {
                            return (
                              <tr
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <td>{item.TextDisplayType}</td>
                                <td>{item.Content}</td>
                                <td>
                                  <BiEdit
                                    style={{ color: "#ff7d26" }}
                                    onClick={() => {
                                      setshow1(true);
                                      setEditData(item);
                                    }}
                                  />
                                  /
                                  <AiFillDelete
                                    style={{ color: "red" }}
                                    onClick={() => DeleteCategory(item._id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  animation={true}
                  style={{ marginTop: "5%" }}
                >
                  <Modal.Header></Modal.Header>
                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <select
                      style={{
                        width: "90%",
                        padding: "10px 10px",
                        border: "1px solid #ff7d26",
                        marginBottom: "2%",
                      }}
                      onChange={(e) => {
                        setcategory(e.target.value);
                      }}
                    >
                      <option>Select Type</option>

                      <option value="Scrollable-Text">Scrollable-Text</option>
                      <option value="Block_1-Text">Block_1-Text</option>
                      <option value="Block_2-Text">Block_2-Text</option>
                    </select>

                    <label
                      style={{
                        textAlign: "left",
                        paddingBottom: "10px",
                        fontSize: "22px",
                        color: "#ff7d26",
                        fontWeight: "500",
                      }}
                    >
                      Enter Advertise-Text{" "}
                    </label>
                    <br></br>
                    <textarea
                      rows="4"
                      cols="50"
                      type="text"
                      placeholder="Advertise-Text here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={Category1Name}
                      onChange={(e) => {
                        setCategoryImage(e.target.value);
                      }}
                    />
                    {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                  </div>

                  <Modal.Footer
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ffcc33",
                        border: "none",
                        width: "120px",
                      }}
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>

                    <Button
                      style={{
                        backgroundColor: "#ff7d26",
                        border: "none",
                        width: "180px",
                      }}
                      onClick={AddCategory}
                    >
                      ADD Advertise_Text
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={show1}
                  onHide={handleClose1}
                  animation={true}
                  style={{ marginTop: "5%" }}
                >
                  <Modal.Header></Modal.Header>
                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <select
                      style={{
                        width: "90%",
                        padding: "10px 10px",
                        border: "1px solid #ff7d26",
                        marginBottom: "2%",
                      }}
                      onChange={(e) => {
                        setcategory1(e.target.value);
                      }}
                    >
                      <option>Select Type</option>

                      <option value="Scrollable-Text">Scrollable-Text</option>
                      <option value="Block_1-Text">Block_1-Text</option>
                      <option value="Block_2-Text">Block_2-Text</option>
                    </select>

                    <label
                      style={{
                        textAlign: "left",
                        paddingBottom: "10px",
                        fontSize: "22px",
                        color: "#ff7d26",
                        fontWeight: "500",
                      }}
                    >
                      Enter Text{" "}
                    </label>
                    <br></br>
                    <textarea
                      rows="4"
                      cols="50"
                      type="text"
                      placeholder={EditData?.Content}
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={Category1Name1}
                      onChange={(e) => {
                        setCategoryImage1(e.target.value);
                        if (Category1Name1.length < 1) {
                          setCategoryImage1(EditData?.Content);
                        }
                      }}
                    />
                  </div>

                  <Modal.Footer
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ffcc33",
                        border: "none",
                        width: "120px",
                      }}
                      onClick={handleClose1}
                    >
                      CANCEL
                    </Button>

                    <Button
                      style={{
                        backgroundColor: "#ff7d26",
                        border: "none",
                        width: "180px",
                      }}
                      onClick={EditCategory}
                    >
                      EDIT AdvertiseText
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
}

export default Add_AdvertiseText;
