import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OwlCarousel from "react-owl-carousel";

function Youth() {

    const responsive = {
        0: {
          items: 1,
        },
        450: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 2,
        },
      };
      const user = JSON.parse(sessionStorage.getItem("user"));

      const [dataSource, setdataSource] = useState([]);
      const [dataSource1, setdataSource1] = useState([]);
    
      useEffect(() => {
        AllPartners();
        AllSubCategory();
      }, []);
    
      const AllPartners = async () => {
        let res = await axios.get("https://maargdarshak.in/api/user/getCategory");
        if (res.status === 200) {
          // console.log(res);
          setdataSource(res.data.CategoryInfo);
        }
      };
    
      const AllSubCategory = async () => {
        let res = await axios.get(
          "https://maargdarshak.in/api/user/getHomepageCategory"
        );
        if (res.status === 200) {
          // console.log(res);
          const filterdata = res.data.HomepageCategoryInfo.filter((data)=>data.DisplayAt=="block2")
          setdataSource1(filterdata);
        }
      };

  return (
   <>
      {/* <div className="" id="KHELO INDIA">
                <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
                KHELO INDIA
                </h5>
                <div className="wraper-0  pb-2">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={7}
                    autoplay={true}
                    dots={false}
                    nav={false}
                    items={2}
                    responsive={responsive}
                  >
                    {dataSource1
                      .filter((data) => data.CategoryName == "KHELO INDIA")
                      .map((item) => {
                        return (
                          <div class="item">
                            <h5
                              className="pb-2 pt-3"
                              style={{ color: "#ffa300", height: "70px" }}
                            >
                              {item.Title}
                            </h5>
                            <a
                              href={
                                user
                                  ? item.SubCategoryLink
                                  : "https://maargdarshak.org/login"
                              }
                              style={{ color: "black" }}
                            >
                              <div className="list-3">
                                <div className="list-30">
                                  <div className="">
                                    <figcaption>
                                      <img
                                        src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                        alt="free"
                                        style={{
                                          width: "100%",
                                          height: "151px",
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <div className="entry-title">
                                    <h5 style={{ height: "50px" }}>
                                      {item.SubTitle}
                                    </h5>
                                    <p>{item.Description.slice(0, 300)}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div> */}


             
             
<div className="row m-1">
              
                    {dataSource1
                     
                      .map((item) => {
                        return (
                          <div className="col-lg-6" >
                            <h5
                              className="pb-2 pt-3"
                              style={{ color: "#ffa300", height: "70px",textAlign:"justify" }}
                            >
                              {item.Title}
                            </h5>
                            <a
                              href={
                               item.SubCategoryLink
                                 
                              }
                       target="blank"

                              style={{ color: "black" }}
                            >
                              <div className="list-3" style={{height:user? "310px":"360px"}}>
                                <div className="list-30">
                                  <div className="">
                                    <figcaption>
                                      <img
                                        src={`https://maargdarshak.in/HomepageCat/${item.SubCategoryImage}`}
                                        alt="free"
                                        style={{
                                          width: "100%",
                                          height: "151px",
                                          borderRadius:"20px"
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <div className="entry-title">
                                    <h5 style={{ height: "50px",textAlign:"justify" }}>
                                      {item.SubTitle}
                                    </h5>
                                    <p style={{textAlign:"justify"}}>{item.Description.slice(0, 220)}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                 
                </div>
            

{/* 
              <div className="" id="PMKVY PROGRAM">
            
                <div className="wraper-0  pb-2">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={7}
                    autoplay={true}
                    dots={false}
                    nav={false}
                    items={2}
                    responsive={responsive}
                  >
                    {dataSource1
                      .filter((data) => data.CategoryName == "PMKVY PROGRAM")
                      .map((item) => {
                        return (
                          <div class="item">
                            <h5
                              className="pb-2 pt-3"
                              style={{ color: "#ffa300", height: "70px" }}
                            >
                              {item.Title}
                            </h5>
                            <a
                              href={
                                user
                                  ? item.SubCategoryLink
                                  : "https://maargdarshak.org/login"
                              }
                              style={{ color: "black" }}
                            >
                              <div className="list-3">
                                <div className="list-30">
                                  <div className="">
                                    <figcaption>
                                      <img
                                        src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                        alt="free"
                                        style={{
                                          width: "100%",
                                          height: "151px",
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <div className="entry-title">
                                    <h5 style={{ height: "50px" }}>
                                      {item.SubTitle}
                                    </h5>
                                    <p>{item.Description.slice(0, 300)}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div> */}
   </>
  )
}

export default Youth