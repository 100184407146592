import axios from "axios";
import React, { useEffect, useState } from "react";

function PartnerLogin() {
  const [mobile, setmobile] = useState();
  const [password, setpassword] = useState();
  const [ButtonColor, setButtonColor] = useState("#0060aa");

  useEffect(() => {
    if (mobile?.length == 10 && password?.length > 3) {
      setButtonColor("#ffeb3b");
    } else {
      setButtonColor("#0060aa");
    }
  }, [mobile]);

  useEffect(() => {
    if (mobile?.length == 10 && password?.length > 3) {
      setButtonColor("#ffeb3b");
    } else {
      setButtonColor("#0060aa");
    }
  }, [password]);

  const Login = async (e) => {
    e.preventDefault();
    if (!mobile) {
      alert("Enter Contact Number");
      return;
    }
    if (mobile.length != 10) {
      alert("Enter Contact Number should be 10 digits");
      return;
    }
    if (!password) {
      alert("Enter Password");
      return;
    }
    if (password.length < 4) {
      alert("Password should be more than 3 digits");
      return;
    }
    try {
      const config = {
        url: "/user/PartnerLogin",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          mobile: mobile,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          sessionStorage.setItem("user", JSON.stringify(res.data.admin));
          sessionStorage.setItem("userType", JSON.stringify("Partner"));

          alert("Login Success");
          window.location.assign("/second-page");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };
  return (
    <>
      <div className="">
        <div class="form_wrapper">
          <div class="form_container">
            <div class="title_container">
              <h2>Login Form</h2>
            </div>
            <div class="row clearfix">
              <div class="">
                <form>
                  {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-user"></i>
                    </span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div> */}
                  {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      name="age"
                      placeholder="Email"
                      required
                    />
                  </div> */}
                  <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-phone"></i>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Mobile No"
                      required
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={10}
                      onChange={(e) => {
                        setmobile(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-lock"></i>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Password"
                      required
                      // maxLength={8}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                    />
                  </div>
                  <input
                    class="button"
                    type="submit"
                    value="Login"
                    style={{ backgroundColor: ButtonColor }}
                    onClick={(e) => Login(e)}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerLogin;
