import React from "react";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="foters">
          <div className="footer-de">
            <ul className="kjf-09">
              {/* <li>National</li>
              <li>State</li>
              <li>District</li> */}
              <li>Terms & conditions</li>
              <li>Privacy</li>
              <li>Disclaimer</li>
            </ul>
            <div className="copy-rights">
              <p>Copyrights @ 2023 Maargdarshak</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
