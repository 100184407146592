import React, { useEffect, useState } from "react";
import "./navbar.css";
import { BsFillTelephonePlusFill, BsPencilFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { HiOutlineLightBulb } from "react-icons/hi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GiSellCard } from "react-icons/gi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BiEdit } from "react-icons/bi";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [navigation, setnavigation] = useState("relative");
  const [show1, setshow1] = useState(false);

  const handleClose1 = () => {
    setshow1(false);
  };

  const [dataSource, setdataSource] = useState([]);

  const formdata = new FormData();

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCampaign");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.CampaignInfo);
    }
  };

  const [show, setshow] = useState(false);
  const [show11, setshow11] = useState(false);

  const [username, setusername] = useState("");

  const [Uemail, setUemail] = useState("");

  const [mobile, setmobile] = useState("");
  const [password, setpassword] = useState("");

  const handleClose2 = () => {
    setshow(false);
  };
  const handleClose21 = () => {
    setshow11(false);
  };
  useEffect(() => {
    setshow1(true);
  }, []);

  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [phonenumber, setphonenumber] = useState();
  const [Note, setNote] = useState();
  const [Buy_Sell, setBuy_Sell] = useState();



  const UpdateUser = async (e) => {
   e.preventDefault()

    try {
      const config = {
        url: "/user/editUser",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          userId:user?._id,
          Username: username,
          email: Uemail,
          mobile: mobile,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          sessionStorage.setItem("user", JSON.stringify(res.data.success1));
          alert("updated success");
      
          setshow11(false);
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const AddBuySell = async (e) => {
    e.preventDefault();
    if (!name) {
      alert("Please Enter Name");
      return;
    }
    if (!phonenumber) {
      alert("Please Enter Contact Number");
      return;
    }
    if (phonenumber.length != 10) {
      alert(" Entered Contact Number should be 10 digits");
      return;
    }
    if (!email) {
      alert("Please Enter Email");
      return;
    }
    if (!Note) {
      alert("Please Donation");
      return;
    }

    try {
      const config = {
        url: "/user/AddBuySell",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          Username: name,
          Phonenumber: phonenumber,
          Email: email,
          Note: Note,
          Buy_Sell:Buy_Sell,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("send success");
          setname("");
          setemail("");
          setphonenumber("");
          setshow(false);
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [Logoutdiv, setLogoutdiv] = useState(false);

  return (
    <>
      <div className="header-0" >
        <nav className="navigation">
          <a
            href="/"
            className="brand-name"
            style={{ width: "22%", textDecoration: "none" }}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/MaargdarshakLogo.jpeg"
                // className="roy"
                style={{
                  width: "110px",
                  height: "110px",
                  marginTop: "5px",
                  padding: " 13px",
                }}
              />
              <div>
                <p
                  className=""
                  style={{
                    color: "black",
                    marginTop: "26px",
                    fontSize: "22px",
                  }}
                >
                  <span style={{ color: "#ffa300" }}>Maa</span>
                  <span style={{ color: "#78be04" }}>rg</span>
                  <span style={{ color: "#00b2d6" }}>dar</span>
                  <span style={{ color: " #0060aa" }}>sh</span>
                  <span style={{ color: " #7f3e98" }}>ak</span>
                </p>
                <p
                  style={{
                    color: "#0060aa",
                    fontSize: "15px",
                    marginTop: "0px",
                    marginLeft: "6px",
                  }}
                >
                  Choose a right path ahead...
                </p>
              </div>
            </div>
          </a>

          {/* <a >
                <p
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       Choose a right path Ahead
      </p>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Skills</MenuItem>
        <MenuItem onClick={handleClose}>Training</MenuItem>
        <MenuItem onClick={handleClose}>Employment</MenuItem>
        <MenuItem onClick={handleClose}>Interest</MenuItem>
      </Menu>
                </a> */}

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from Heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            {user ? (
              <div
                style={{ margin: "0px 10px 15px 0px" }}
                onClick={() => setLogoutdiv(!Logoutdiv)}
              >
                <span
                  style={{
                    marginRight: "6px",
                    color: "#0060aa",
                    fontSize: "16px",
                    fontWeight: "800",
                  }}
                >
                  {user.Username.toUpperCase()}
                </span>
                <BiEdit
                                    style={{ color: "black",fontSize:"20px" }}
                                    onClick={() => {
                                      setshow11(true);
                                    
                                    }}
                                  />
                <img
                  src={`https://maargdarshak.in/ProfileImage/${user.UserImage}`}
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                ></img>

                <h6
                  style={{
                    position: "absolute",
                    top: "66%",
                    color: "#ff7d26",
                  }}
                  onClick={() => {
                    sessionStorage.removeItem("user");
                    window.location.reload();
                  }}
                >
                  Logout
                </h6>
              </div>
            ) : (
              <ul >
                {/* <li>
                <a href="/blog1">Blog</a>
              </li> */}
                <li style={{ color: "blue" }}>
                  <a onClick={() => setshow(true)} style={{fontSize:"20px"}}>
                    <span style={{ paddingRight: "8px" }}>
                      <GiSellCard />
                    </span>
                    Buy & Sell
                  </a>
                </li>
                <li>
                  <a href="/non-registion" style={{fontSize:"20px"}}>
                    <span style={{ paddingRight: "8px" }}>
                      <BsPencilFill />
                    </span>
                    Please leave a note
                  </a>
                </li>
                {/* <li>
                <a href="#">
                  <span style={{paddingRight:"8px"}}>
                    <BsFillTelephonePlusFill />
                  </span>
                  9620688988
                </a>
              </li> */}
                <li>
                  <a href="#" style={{fontSize:"20px"}}>
                    <span style={{ paddingRight: "8px" }}>
                      <IoIosMail />
                    </span>
                    talktous@maargdarshak.org
                  </a>
                </li>
                <li>
                  <a className="Login" href="/login" style={{fontSize:"20px"}}> Login</a>
                </li>
                <li>
                  <a className="Login" href="/Partnerlogin" style={{fontSize:"20px"}} > Partner Login</a>
                </li>
                <li>
                  <a  className="Login" href="/register" style={{fontSize:"20px"}}>Register</a>
                </li>
                {/* <li>
                <a href="/register">Contact Us</a>
              </li> */}
              </ul>
            )}
          </div>
        </nav>

        <Modal
          show={show1}
          onHide={handleClose1}
          animation={true}
          style={{ marginTop: "5%" }}
        >
          <Modal.Header closeButton>
            <span
              style={{
                color: "grey",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {dataSource[0]?.CampaignTitle}
            </span>
          </Modal.Header>
          <Modal.Body>
            <div class="col-md-12" style={{ margin: "2% 4%" }}>
              <div style={{ width: "90%" }}>
                <img
                  src={`https://maargdarshak.in/campaign/${dataSource[0]?.CampaignImage}`}
                  style={{ width: "180px", height: "120px" }}
                ></img>
                <h6 style={{ textAlign: "justify" }}>
                  {dataSource[0]?.CampaignDescription}
                </h6>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#ff7d26",
                border: "none",
                width: "180px",
              }}
              onClick={() =>
                window.location.assign(dataSource[0]?.CampaignLink)
              }
            >
              VIEW MORE
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose2}
          animation={true}
          style={{ marginTop: "5%" }}
        >
          <Modal.Header>
            <h4>BUY & SELL</h4>
          </Modal.Header>
          <div class="col-md-12" style={{ margin: "2% 4%" }}>
            <input
              type="text"
              placeholder="name here..."
              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={name}
              onChange={(e) => {
                setname(e.target.value);
              }}
            />

            <input
              type="text"
              placeholder="email here..."
              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />

            <input
              type="text"
              placeholder="phonenumber here..."
              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={phonenumber}
              onChange={(e) => {
                setphonenumber(e.target.value);
              }}
            />



            <select   style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              onChange={(e) => {
                setBuy_Sell(e.target.value);
              }}>
              <option>select buy Or sell</option>
              <option value="buy">buy</option>
              <option value="sell">sell</option>

            </select>

            <input
              type="text"
              placeholder="Note here..."
              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={Note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />

            {/* <input
                      type="text"
                      placeholder="Donation Collected here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    /> */}

            {/* <input
                      type="text"
                      placeholder="SubCategoryLink here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={SubCategoryLink}
                      onChange={(e) => {
                        setSubCategoryLink(e.target.value);
                      }}
                    /> */}

            {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
          </div>

          <Modal.Footer
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#ffcc33",
                border: "none",
                width: "120px",
              }}
              onClick={handleClose2}
            >
              CANCEL
            </Button>

            <Button
              style={{
                backgroundColor: "#ff7d26",
                border: "none",
                width: "180px",
              }}
              onClick={AddBuySell}
            >
              SEND
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show11}
          onHide={handleClose21}
          animation={true}
          style={{ marginTop: "5%" }}
        >
          <Modal.Header>
            <h4>Edit Profile</h4>
          </Modal.Header>
          <div class="col-md-12" style={{ margin: "2% 4%" }}>
            <input
              type="text"
              placeholder={user?.Username}
              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={username}
              onChange={(e) => {
                setusername(e.target.value);
              }}
            />

            <input
              type="number"
              placeholder={user?.Mobile}

              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={mobile}
              onChange={(e) => {
                setmobile(e.target.value);
              }}
            />

            <input
              type="email"
              placeholder={user?.email}

              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={Uemail}
              onChange={(e) => {
                setUemail(e.target.value);
              }}
            />

            <input
              type="text"
              placeholder={user?.password}

              style={{
                padding: "10px",
                width: "90%",
                border: "1px solid #ff7d26",
                marginBottom: "2%",
              }}
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />

         
          </div>

          <Modal.Footer
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#ffcc33",
                border: "none",
                width: "120px",
              }}
              onClick={handleClose21}
            >
              CANCEL
            </Button>

            <Button
              style={{
                backgroundColor: "#ff7d26",
                border: "none",
                width: "180px",
              }}
              onClick={UpdateUser}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Header;
