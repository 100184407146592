import "./AdminLogin.css";
import React, { useState, useEffect } from "react";

import Carousel from "react-bootstrap/Carousel";
import { ImQuotesLeft } from "react-icons/im";
import { MdFacebook } from "react-icons/md";
import { GrTwitter } from "react-icons/gr";
import { AiOutlineGoogle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import axios from "axios";

function AdminLogin() {
  const [email, setemail] = useState();
  const [password, setpassword] = useState();

  const Login = () => {
    if (email == "anandsarkar@gmail.com" && password == "12345678") {
      window.location.assign("/Admindashboard");
      sessionStorage.setItem("admin", JSON.stringify(true));
    }
    // try {
    //   const config = {
    //     url: "/admin/signin",
    //     method: "post",
    //     baseURL: "https://eloerise.in/api",
    //     hearder: { "content-type": "application/json" },
    //     data: { email: email, password: password },
    //   };
    //   await axios(config).then(function (res) {
    //     if (res.status === 200) {
    //       console.log(res.data);
    //       sessionStorage.setItem("user", JSON.stringify(res.data.admin));
    //       sessionStorage.setItem("admin", JSON.stringify(res.data.admin));

    //       window.location.assign("/Admindashboard");
    //     }
    //   });
    // } catch (error) {
    //   console.log(error);
    //   alert(error.response.data.error);
    // }
  };

  return (
    <div className="AdminLogin">
      <div class="p-0 container-fluid">
        <div class="g-0 row me-0">
          <div class="col-xl-9">
            <div class="auth-full-bg pt-lg-5 p-4">
              <div class="w-100">
                <div
                  class="bg-overlay"
                  style={{ backgroundColor: "#ffcc33" }}
                ></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="auth-full-page-content p-md-5 p-4">
              <div class="w-100">
                <div class="d-flex flex-column h-100">
                  <div style={{ textAlign: "center" }}>
                    <a class="d-block auth-logo" href="/dashboard">
                      <img
                        src="https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
                        alt=""
                        // height="24"
                        width="40%"
                        class="auth-logo-dark"
                      />
                    </a>
                  </div>
                  <div class="my-auto">
                    <div>
                      <h5 class="text-primary1" style={{ textAlign: "left" }}>
                        Welcome Back !
                      </h5>
                      <p class="text-muted" style={{ textAlign: "left" }}>
                        Sign in to enter your account dashboard.
                      </p>
                    </div>
                    <div class="mt-4">
                      <form class="form-horizontal">
                        <div class="mb-3">
                          <input
                            name="email"
                            placeholder="Enter email"
                            type="email"
                            class="form-control"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div class="mb-3">
                          <input
                            name="password"
                            placeholder="Enter Password"
                            type="password"
                            class="form-control"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </div>

                        <div class="mt-3 d-grid">
                          <p
                            class="btn btn-primary btn-block"
                            style={{
                              backgroundColor: "#ff7d26",
                              color: "white",
                              width: "100%",
                            }}
                            onClick={Login}
                          >
                            {" "}
                            Log In{" "}
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class=" text-center">
                    <p class="mb-0" style={{ fontSize: "16px" }}>
                      © Maargdarshak.org 2023 &#174;{" "}
                      <i class="mdi mdi-heart text-danger"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
