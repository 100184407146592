import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Login from "./Components/Login";
import NonRagister from "./Components/NonRagister";
import Otp from "./Components/Otp";
import Register from "./Components/Register";
import PartnerLogin from "./Components/PartnerLogin";

//Admin Login
import Adminlogin from "./Components/Admin/Components/AdminLogin";
import Admindashboard from "./Components/Admin/Components/Admindashboard";
import AdminHeader from "./Components/AdminHeader";
import DonateMoney from "./Components/DonateMoney";
import SecondPages from "./Components/SecondPages";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Header />
                <Register />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
              </>
            }
          />
          <Route
            path="/second-page"
            element={
              <>
                <Header />
                <SecondPages />
              </>
            }
          />
          <Route
            path="/Partnerlogin"
            element={
              <>
                <Header />
                <PartnerLogin />
              </>
            }
          />
          <Route
            path="/otp"
            element={
              <>
                <Header />
                <Otp />
              </>
            }
          />
          <Route
            path="/non-registion"
            element={
              <>
                <Header />
                <NonRagister />
              </>
            }
          />
          <Route
            path="/donate"
            element={
              <>
                <Header />
                <DonateMoney />
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <>
                <AdminHeader />
                <Adminlogin />
              </>
            }
          />

          <Route
            path="/admindashboard"
            element={
              <>
                <AdminHeader />

                <Admindashboard />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
