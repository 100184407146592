import axios from "axios";
import React, { useEffect, useState } from "react";

function Login() {
  const [mobile, setmobile] = useState();
  const [password, setpassword] = useState();
  const [ButtonColor, setButtonColor] = useState("#0060aa");

  useEffect(() => {
    if (mobile?.length == 10 && password?.length > 3) {
      setButtonColor("#ffeb3b");
    } else {
      setButtonColor("#0060aa");
    }
  }, [mobile]);

  useEffect(() => {
    if (mobile?.length == 10 && password?.length > 3) {
      setButtonColor("#ffeb3b");
    } else {
      setButtonColor("#0060aa");
    }
  }, [password]);

  useEffect(() => {
    AllPartners();
  }, []);
  const Login = async (e) => {
    e.preventDefault();
    if (!mobile) {
      alert("Enter Contact Number Or Email Id");
      return;
    }
    // if (mobile.length != 10) {
    //   alert("Enter Contact Number should be 10 digits");
    //   return;
    // }
    if (!password) {
      alert("Enter Password");
      return;
    }
    if (password.length < 4) {
      alert("Password should be more than 3 digits");
      return;
    }
    try {
      const config = {
        url: "/user/Login",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          mobile: mobile,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          sessionStorage.setItem("user", JSON.stringify(res.data.admin));
          sessionStorage.setItem("userType", JSON.stringify("User"));

          alert("Login Success");
          window.location.assign("/");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };

  const [dataSource, setdataSource] = useState([]);

  const AllPartners = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getLoginAdvertise"
    );
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.LoginAdvertiseInfo);
    }
  };

  console.log(dataSource, "dfghj");
  return (
    <>
      {dataSource?.length > 0 ? (
        <div className="">
          <div className="row">
            <div className="col-lg-4 p-4">
              {" "}
              <marquee width="100%" height="20px">
                {dataSource[0]?.Title}
              </marquee>
              <a href={dataSource[0]?.Link} target="blank">
                <img
                  src={`https://maargdarshak.in/LoginAdvertise/${dataSource[0]?.Image}`}
                  style={{
                    width: "100%",
                    height: "260px",
                  }}
                ></img>
              </a>
            </div>
            <div className="col-lg-4">
              {" "}
              <div class="form_wrapper">
                <div class="form_container">
                  <div class="title_container">
                    <h2>Login</h2>
                  </div>
                  <div class="row clearfix">
                    <div class="">
                      <form>
                        {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-user"></i>
                    </span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div> */}
                        {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      name="age"
                      placeholder="Email"
                      required
                    />
                  </div> */}
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-phone"></i>
                          </span>
                          <input
                            type="text"
                            name="mobile"
                            placeholder="Mobile No Or Email"
                            required
                            // onKeyPress={(event) => {
                            //   if (!/[0-9]|\./.test(event.key)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                            // maxLength={10}
                            onChange={(e) => {
                              setmobile(e.target.value);
                            }}
                          />
                        </div>
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-lock"></i>
                          </span>
                          <input
                            type="text"
                            name="mobile"
                            placeholder="Password"
                            required
                            // maxLength={8}
                            onChange={(e) => {
                              setpassword(e.target.value);
                            }}
                          />
                        </div>
                        <input
                          class="button"
                          style={{ backgroundColor: ButtonColor }}
                          type="submit"
                          value="Login"
                          onClick={(e) => Login(e)}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {dataSource
              ?.filter((item, index) => index != 0)
              .map((data) => {
                return (
                  <div className="col-lg-4 p-4">
                    {" "}
                    <marquee width="100%" height="20px">
                      {data?.Title}
                    </marquee>
                    <a href={data?.Link} target="blank">
                      <img
                        src={`https://maargdarshak.in/LoginAdvertise/${data?.Image}`}
                        style={{
                          width: "100%",
                          height: "260px",
                        }}
                      ></img>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="">
          {" "}
          <div class="form_wrapper">
            <div class="form_container">
              <div class="title_container">
                <h2>Login</h2>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form>
                    {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-user"></i>
                    </span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    />
                  </div> */}
                    {/* <div class="input_field">
                    {" "}
                    <span>
                      <i aria-hidden="true" class="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      name="age"
                      placeholder="Email"
                      required
                    />
                  </div> */}
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Mobile No"
                        required
                        onKeyPress={(event) => {
                          if (!/[0-9]|\./.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={10}
                        onChange={(e) => {
                          setmobile(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Password"
                        required
                        // maxLength={8}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                    <input
                      class="button"
                      style={{ backgroundColor: ButtonColor }}
                      type="submit"
                      value="Login"
                      onClick={(e) => Login(e)}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
