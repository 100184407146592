import "./AdminLogin.css";
import React, { useState, useEffect } from "react";

import Carousel from "react-bootstrap/Carousel";
import { ImQuotesLeft } from "react-icons/im";
import { MdFacebook } from "react-icons/md";
import { GrTwitter } from "react-icons/gr";
import { AiOutlineGoogle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import axios from "axios";
import User from "./User";
import PARTNER from "./partner";
import REGISTERED from "./registerenquiry";
import NONREGISTERED from "./nonregisterenquiry";
import Add_Category from "./AddCategory";
import Add_SubCategory from "./AddSubCategory";
import Add_Adversitement from "./AddAdversitement";
import Add_AdvertisementCategory from "./AddAdvertiseSubCategory";
import Add_Donate from "./AddDonation";
import Add_Banner from "./Banners";
import Add_Video from "./AddVideo";
import Add_HomeCategory from "./AddHomeCategory";
import Add_Category1 from "./AddCategory1";
import Add_LoginAdvertise from "./AddLoginAdvertise";
import Add_AdvertiseText from "./AddScrollableText";
import Adminpincode from "./AddPincode";
import Add_Pincode from "./AddPincode";
import Add_Campaign from "./Addcampaign";
import BuySell from "./BuySell";

function Admindashboard() {
  const [tab, settab] = useState(<User />);
  const user = sessionStorage.getItem("admin");
  // if (user) {
  // } else {
  //   window.location.assign("/admin");
  // }
  return (
    <div className="AdminLogin">
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            textAlign: "left",
            paddingLeft: "15px",
            backgroundColor: "#ff7d26",
            height: "570px",
            position: "fixed",
            color: "white",
            overflow: "auto",
            // height: "60%",
          }}
        >
          <h6 style={{ padding: "8px 0px" }} onClick={() => settab(<User />)}>
            USER
          </h6>{" "}
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<PARTNER />)}
          >
            PARTNER
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<REGISTERED />)}
          >
            REGISTERED ENQUIRY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<NONREGISTERED />)}
          >
            NON-REGISTERED ENQUIRY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<BuySell />)}
          >
            BUY & SELL
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_HomeCategory />)}
          >
            ADD HOME-CATEGORY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Category />)}
          >
            ADD CATEGORY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Category1 />)}
          >
            ADD SUB-CATEGORY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_SubCategory />)}
          >
            ADD LINKS
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Adversitement />)}
          >
            ADD ADVERSITEMENT
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Campaign />)}
          >
            ADD CAMPAIGN
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_AdvertisementCategory />)}
          >
            ADD ADVERSITEMENT CATEGORY
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Donate />)}
          >
            ADD DONATE
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Banner />)}
          >
            ADD BANNER
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Video />)}
          >
            ADD VIDEO
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_LoginAdvertise />)}
          >
            ADD LOGIN ADVERTISE
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_AdvertiseText />)}
          >
            ADD ADVERTISE-TEXT
          </h6>
          <h6
            style={{ padding: "8px 0px" }}
            onClick={() => settab(<Add_Pincode />)}
          >
            ADD PINCODE
          </h6>
        </div>
        <div style={{ width: "80%", marginLeft: "20%" }}>{tab} </div>
      </div>
    </div>
  );
}

export default Admindashboard;
