import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import NationlNews from "./NationlNews";
import District from "./District";
import Statenews from "./Statenews";
import SkillDev from "./SkillDev";
import Policy from "./Policy";
import Teachers from "./Teachers";
import Health from "./Health";
import ChildWomen from "./ChildWomen";
import TechApp from "./TechApp";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function News() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 2,
    },
  };

  const responsive1 = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  const responsive2 = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [dataSource, setdataSource] = useState([]);
  const [dataSource1, setdataSource1] = useState([]);

  useEffect(() => {
    AllPartners();
    AllSubCategory();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCategory");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.CategoryInfo);
    }
  };

  const AllSubCategory = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getHomepageCategory"
    );
    if (res.status === 200) {
      // console.log(res);
      const filterdata = res.data.HomepageCategoryInfo.filter(
        (data) => data.DisplayAt === "block1"
      );
      setdataSource1(filterdata);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const ShowModal = () => {
    setShow(true);
  };
  return (
    <>
      <div className="diff mb-3 mt-3">
        <div className="news-d">
          <div>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={7}
              autoplay={true}
              dots={false}
              nav={false}
              items={2}
              responsive={responsive2}
            >
              {dataSource?.map((item) => {
                return (
                  <div>
                    <div className="news-p-0">
                      {user ? (
                        <div className="figa-09">
                          <Link
                            to="/second-page"
                            state={{ Category: item.CategoryName }}
                          >
                            <figcaption className="nirma">
                              <img
                                src={`https://maargdarshak.in/CategoryImage/${item.CategoryImage}`}
                                alt=""
                                style={{ width: "70px", height: "60px" }}
                              />
                            </figcaption>
                          </Link>

                          <h6>{item.CategoryName}</h6>
                        </div>
                      ) : (
                        <div className="figa-09">
                          {/* <a href={user ? "/second-page" : "/login"}> */}
                          <figcaption className="nirma">
                            <img
                              src={`https://maargdarshak.in/CategoryImage/${item.CategoryImage}`}
                              alt=""
                              onClick={() => {
                                ShowModal();
                              }}
                              style={{ width: "70px", height: "60px" }}
                            />
                          </figcaption>
                          {/* </a> */}
                          <h6
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            {item.CategoryName}
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              {/* <div>
                <div className="news-p-0">
                  <div className="figa-09">
                    <a href="#skill">
                      <figcaption className="nirma">
                        <img
                          src="/image/skill.png"
                          alt=""
                          style={{ width: "70px", height: "60px" }}
                        />
                      </figcaption>
                    </a>
                    <h6>Skill Development</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  <div className="figa-09">
                    <a href="#skill1">
                      <figcaption className="nirma">
                        <img
                          src="/image/education11.svg"
                          alt=""
                          style={{ width: "70px", height: "60px" }}
                        />
                      </figcaption>
                    </a>
                    <h6>Educational Institutions</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  <div className="figa-09">
                    <a href="#skill3">
                      <figcaption className="nirma">
                        <img
                          src="/image/em-3.png"
                          alt=""
                          style={{ width: "70px", height: "60px" }}
                        />
                      </figcaption>
                    </a>
                    <h6>TEACHERS & ASSESSORES</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  {" "}
                  <div className="figa-09">
                    <a href="#erd1">
                      <figcaption className="nirma">
                        <img
                          src="/image/em-4.png"
                          alt=""
                          style={{ width: "70px", height: "60px" }}
                        />
                      </figcaption>
                    </a>
                    <h6>Policies</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  {" "}
                  <div className="figa-09">
                    <a href="#skill5">
                      <figcaption className="nirma">
                        <img
                          src="/image/tools.png"
                          alt=""
                          style={{ width: "70px", height: "60px" }}
                        />
                      </figcaption>
                    </a>
                    <h6>Technology Tools & Apps</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  <div className="figa-09">
                    <figcaption className="nirma">
                      <img
                        src="/image/buy.png"
                        alt=""
                        style={{ width: "70px", height: "60px" }}
                      />
                    </figcaption>
                    <h6>Buy & Sell</h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="news-p-0">
                  <div className="figa-09">
                    <figcaption className="nirma">
                      <img
                        src="/image/skill.png"
                        alt=""
                        style={{ width: "70px", height: "60px" }}
                      />
                    </figcaption>
                    <h6>Interviews,Coaching & Counselling</h6>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="news-p-0">
                    <div className="figa-09">
                      <a href="#skill4">
                        <figcaption className="nirma">
                          <img
                            src="/image/welfare.png"
                            alt=""
                            style={{ width: "70px", height: "60px" }}
                          />
                        </figcaption>
                      </a>
                      <h6>Child & Women welfare</h6>
                    </div>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </div>

        <div className="ne-diif">
          <NationlNews />

          <div className="row m-1">
            {dataSource1
              .filter((data, index) => index < 4)
              .map((item) => {
                return (
                  <div className="col-lg-6">
                    <h5
                      className="pb-2 pt-3"
                      style={{
                        color: "#ffa300",
                        height: "70px",
                        textAlign: "justify",
                      }}
                    >
                      {item.Title}
                    </h5>
                    <a
                      href={item.SubCategoryLink}
                      style={{ color: "black" }}
                      target="blank"
                    >
                      <div className="list-3" style={{ height: "280px" }}>
                        <div className="list-30">
                          <div className="">
                            <figcaption>
                              <img
                                src={`https://maargdarshak.in/HomepageCat/${item.SubCategoryImage}`}
                                alt="free"
                                style={{
                                  width: "100%",
                                  height: "120px",
                                  borderRadius: "20px",
                                }}
                              />
                            </figcaption>
                          </div>
                          <div className="entry-title">
                            <h5 style={{ height: "50px" }}>{item.SubTitle}</h5>
                            {item.Description.lenght > 240 ? (
                              <p style={{ textAlign: "justify" }}>
                                {item.Description.slice(0, 240)}...
                              </p>
                            ) : (
                              <p style={{ textAlign: "justify" }}>
                                {item.Description.slice(0, 240)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
          <div className="row m-1">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={7}
              autoplay={true}
              dots={false}
              nav={false}
              items={2}
              responsive={responsive1}
            >
              {dataSource1
                .filter((data, index) => index > 3)
                .map((item) => {
                  return (
                    <div>
                      <h5
                        className="pb-2 pt-3"
                        style={{ color: "#ffa300", height: "70px" }}
                      >
                        {item.Title}
                      </h5>
                      <a
                        href={item.SubCategoryLink}
                        style={{ color: "black" }}
                        target="blank"
                      >
                        <div className="list-3" style={{ height: "280px" }}>
                          <div className="list-30">
                            <div className="">
                              <figcaption>
                                <img
                                  src={`https://maargdarshak.in/HomepageCat/${item.SubCategoryImage}`}
                                  alt="free"
                                  style={{
                                    width: "100%",
                                    height: "120px",
                                    borderRadius: "20px",
                                  }}
                                />
                              </figcaption>
                            </div>
                            <div className="entry-title">
                              <h5 style={{ height: "50px" }}>
                                {item.SubTitle}
                              </h5>
                              {item.Description.lenght > 240 ? (
                                <p style={{ textAlign: "justify" }}>
                                  {item.Description.slice(0, 240)}...
                                </p>
                              ) : (
                                <p style={{ textAlign: "justify" }}>
                                  {item.Description.slice(0, 240)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
          {/* <div className="" id="GOVERNMENT">
            <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
              GOVERNMENT
            </h5>
            <div className="wraper-0  pb-2">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={7}
                autoplay={true}
                dots={false}
                nav={false}
                items={2}
                responsive={responsive}
              >
                {dataSource1
                  .filter((data) => data.CategoryName == "GOVERNMENT")
                  .map((item) => {
                    return (
                      <div class="item">
                        <h5
                          className="pb-2 pt-3"
                          style={{ color: "#ffa300", height: "70px" }}
                        >
                          {item.Title}
                        </h5>
                        <a
                          href={
                            user
                              ? item.SubCategoryLink
                              : "https://maargdarshak.org/login"
                          }
                          style={{ color: "black" }}
                        >
                          <div className="list-3">
                            <div className="list-30">
                              <div className="">
                                <figcaption>
                                  <img
                                    src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                    alt="free"
                                    style={{
                                      width: "100%",
                                      height: "151px",
                                    }}
                                  />
                                </figcaption>
                              </div>
                              <div className="entry-title">
                                <h5 style={{ height: "50px" }}>
                                  {item.SubTitle}
                                </h5>
                                <p>{item.Description.slice(0, 300)}</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div>

          <div className="" id="EDUCATION & EMPLOYMENT">
            <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
              EDUCATION & EMPLOYMENT
            </h5>
            <div className="wraper-0  pb-2">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={7}
                autoplay={true}
                dots={false}
                nav={false}
                items={2}
                responsive={responsive}
              >
                {dataSource1
                  .filter(
                    (data) => data.CategoryName == "EDUCATION & EMPLOYMENT"
                  )
                  .map((item) => {
                    return (
                      <div class="item">
                        <h5
                          className="pb-2 pt-3"
                          style={{ color: "#ffa300", height: "70px" }}
                        >
                          {item.Title}
                        </h5>
                        <a
                          href={
                            user
                              ? item.SubCategoryLink
                              : "https://maargdarshak.org/login"
                          }
                          style={{ color: "black" }}
                        >
                          <div className="list-3">
                            <div className="list-30">
                              <div className="">
                                <figcaption>
                                  <img
                                    src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                    alt="free"
                                    style={{
                                      width: "100%",
                                      height: "151px",
                                    }}
                                  />
                                </figcaption>
                              </div>
                              <div className="entry-title">
                                <h5 style={{ height: "50px" }}>
                                  {item.SubTitle}
                                </h5>
                                <p>{item.Description.slice(0, 300)}</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div>

          <div className="" id="SKILL DEVELOPMENT">
            <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
              SKILL DEVELOPMENT
            </h5>
            <div className="wraper-0  pb-2">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={7}
                autoplay={true}
                dots={false}
                nav={false}
                items={2}
                responsive={responsive}
              >
                {dataSource1
                  .filter((data) => data.CategoryName == "SKILL DEVELOPMENT")
                  .map((item) => {
                    return (
                      <div class="item">
                        <h5
                          className="pb-2 pt-3"
                          style={{ color: "#ffa300", height: "70px" }}
                        >
                          {item.Title}
                        </h5>
                        <a
                          href={
                            user
                              ? item.SubCategoryLink
                              : "https://maargdarshak.org/login"
                          }
                          style={{ color: "black" }}
                        >
                          <div className="list-3">
                            <div className="list-30">
                              <div className="">
                                <figcaption>
                                  <img
                                    src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                    alt="free"
                                    style={{
                                      width: "100%",
                                      height: "151px",
                                    }}
                                  />
                                </figcaption>
                              </div>
                              <div className="entry-title">
                                <h5 style={{ height: "50px" }}>
                                  {item.SubTitle}
                                </h5>
                                <p>{item.Description.slice(0, 300)}</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div> */}

          {/* {dataSource.map((item) => {
            return (
              <div className="" id={item.CategoryName}>
                <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
                  {dataSource1.filter(
                    (data) => data.CategoryName == item.CategoryName
                  ).length < 1
                    ? null
                    : item.CategoryName}
                </h5>
                <div className="wraper-0  pb-2">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={7}
                    autoplay={true}
                    dots={false}
                    nav={false}
                    items={2}
                    responsive={responsive}
                  >
                    {dataSource1
                      .filter((data) => data.CategoryName == item.CategoryName)
                      .map((item) => {
                        return (
                          <div class="item">
                            <h5
                              className="pb-2 pt-3"
                              style={{ color: "#ffa300", height: "70px" }}
                            >
                              {item.Title}
                            </h5>
                            <a
                              href={
                                user
                                  ? item.SubCategoryLink
                                  : "https://maargdarshak.org/login"
                              }
                              style={{ color: "black" }}
                            >
                              <div className="list-3">
                                <div className="list-30">
                                  <div className="">
                                    <figcaption>
                                      <img
                                        src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                        alt="free"
                                        style={{
                                          width: "100%",
                                          height: "151px",
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <div className="entry-title">
                                    <h5 style={{ height: "50px" }}>
                                      {item.SubTitle}
                                    </h5>
                                    <p>{item.Description.slice(0, 300)}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div>
            );
          })} */}
          {/* <SkillDev />
          <Policy />
          <District />
          <Teachers />
          <Health />
          <ChildWomen />
          <TechApp /> */}
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h4 style={{ textAlign: "center", color: "#ff7d26" }}>
              NEED TO REGISTER/LOGIN
            </h4>
          </Modal.Header>

          <Modal.Body>
            <div className="herp-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "6% 0% 0% 0%",
                }}
              >
                <button
                  style={{
                    padding: "2% 4%",
                    backgroundColor: "rgb(0, 96, 170)",
                    color: "white",
                  }}
                >
                  <a
                    href="/register"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    REGISTER
                  </a>
                </button>
                <button
                  style={{
                    padding: "2% 6%",
                    backgroundColor: "rgb(0, 96, 170)",
                    color: "white",
                  }}
                >
                  <a
                    href="/login"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    LOGIN
                  </a>
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default News;

// =====================
{
  /* <div className="urr-0">
<Box
  sx={{ maxWidth: { xs: 320, sm: 600 }, bgcolor: "background.paper" }}
>
  <Tabs
    value={value}
    onChange={handleChange}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
  >
    <Tab label="Highlights" onClick={()=>{
      setacc1(true)
      setacc2(false)
      setacc3(false)
    }} />
    <Tab label="Employment"   onClick={()=>{
      setacc1(false)
      setacc2(true)
      setacc3(false)
    }} />
    
    <Tab label="Education" />
    <Tab label="State Highlights" />
    <Tab label="Policy" />
    
  </Tabs>

</Box>
<p style={{marginTop:"10px"}}><a href="">ViewAll</a></p>
</div> */
  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
}
