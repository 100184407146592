// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import "./Directory.css";
import ReactPaginate from "react-paginate";
import { FcDonate } from "react-icons/fc";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

function Add_Donate() {
  const [dataSource, setdataSource] = useState([]);
  const [dataSource1, setdataSource1] = useState([]);

  const formdata = new FormData();

  useEffect(() => {
    AllPartners();
    AllDonationCollected();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getDonate");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.DonateInfo);
    }
  };

  const [DonationCollected, setDonationCollected] = useState([]);

  const AllDonationCollected = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getDonationCollected"
    );
    if (res.status === 200) {
      // console.log(res);
      setDonationCollected(res.data.DonationCollectedInfo);
    }
  };

  const [DonationTitle, setDonationTitle] = useState();
  const [DonationLink, setSubCategoryImage] = useState();
  const [DonationImage, setDonationImage] = useState();

  const [show, setshow] = useState(false);

  const handleClose = () => {
    setshow(false);
  };

  const AddCategory = async (e) => {
    e.preventDefault();
    if (!DonationTitle) {
      alert("Please Enter Category Name");
      return;
    }
    if (!DonationImage) {
      alert("Please Select Image");
      return;
    }
    formdata.append("DonationTitle", DonationTitle.toUpperCase());
    formdata.append("DonationLink", DonationLink);
    formdata.append("DonationCollected", DonationCollected);
    formdata.append("DonationImage", DonationImage);

    try {
      const config = {
        url: "/user/addDonate",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Donation Added");
          setshow(false);

          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 3;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [ViewDonationCollected, setViewDonationCollected] = useState(false);
  const [Selected, setSelected] = useState({});

  const ViewDonation = (item) => {
    setSelected(item);
    setViewDonationCollected(true);
  };

  const [EditData, setEditData] = useState({});

  const [show1, setshow1] = useState(false);

  const handleClose1 = () => {
    setshow1(false);
  };

  const [DonationTitle1, setDonationTitle1] = useState();
  const [DonationLink1, setSubCategoryImage1] = useState();
  const [DonationImage1, setDonationImage1] = useState();

  const EditDonateImg = async () => {
    formdata.append("userId", EditData?._id);
    formdata.append("DonationImage", DonationImage1);

    try {
      const config = {
        url: "/user/editDonateImage",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Donation Updated");
          setshow(false);

          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const EditDonate = async () => {
    // formdata.append("DonationTitle", DonationTitle.toUpperCase());
    // formdata.append("DonationLink", DonationLink);
    // formdata.append("DonationCollected", DonationCollected);
    // formdata.append("DonationImage", DonationImage);

    try {
      const config = {
        url: "/user/editDonate",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          userId: EditData?._id,
          DonationTitle: DonationTitle1,
          DonationLink: DonationLink1,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          setshow1(false);
          EditDonateImg();
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const DeleteCategory = async (Id) => {
    try {
      const config = {
        url: "/user/RemoveDonate",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { DonateId: Id },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Donate Deleted");
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 0px 20px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {ViewDonationCollected ? "DONATION COLLECTED" : "DONATE"}
          </h6>
          {ViewDonationCollected ? (
            <button
              style={{
                backgroundColor: "#ff7d26",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                padding: "10px 10px",
                borderRadius: "20px",
              }}
              onClick={() => setViewDonationCollected(false)}
            >
              BACK
            </button>
          ) : (
            <button
              style={{
                backgroundColor: "#ff7d26",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                padding: "10px 10px",
                borderRadius: "20px",
              }}
              onClick={() => setshow(true)}
            >
              +ADD DONATE
            </button>
          )}
        </div>
        {ViewDonationCollected ? (
          <div class="row">
            <div class="col-12">
              <div class="EloetableOrder">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "96%", margin: "2%" }}
                      >
                        <thead
                          style={{ backgroundColor: "#ff7d26", color: "white" }}
                        >
                          <tr style={{ padding: "10px", textAlign: "center" }}>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              NAME{" "}
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th> CONTACT NUMBER</th>
                            {/* <th> DONATION COLLECTED</th> */}
                            <th> EMAIL</th>
                            <th> DONATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {DonationCollected.filter(
                            (data) => data.DonationId == Selected._id
                          ).map((item) => {
                            return (
                              <tr
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <td>{item.Username}</td>

                                <td>{item.phonenumber}</td>
                                <td>{item.Email}</td>
                                <td>{item.Donation}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col-12">
              <div class="EloetableOrder">
                <div class="card-body">
                  <div class="table-responsive">
                    <div class="react-bootstrap-table">
                      <table
                        class="table table align-middle table-nowrap"
                        style={{ width: "96%", margin: "2%" }}
                      >
                        <thead
                          style={{ backgroundColor: "#ff7d26", color: "white" }}
                        >
                          <tr style={{ padding: "10px", textAlign: "center" }}>
                            <th
                              tabindex="0"
                              aria-label="Phone / Email sortable"
                              class="sortable"
                            >
                              DONATION NAME{" "}
                              <span class="order">
                                <span class="dropdown">
                                  <span class="caret"></span>
                                </span>
                                <span class="dropup">
                                  <span class="caret"></span>
                                </span>
                              </span>
                            </th>

                            <th> DONATION IMAGE</th>
                            {/* <th> DONATION COLLECTED</th> */}
                            <th> DONATION LINK</th>
                            <th> DONATION REQUESTED</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataSource
                            .slice(pagesVisited, pagesVisited + usersPerPage)
                            .map((item) => {
                              return (
                                <tr
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <td>{item.DonationTitle}</td>
                                  <td>
                                    <img
                                      src={`https://maargdarshak.in/Donate/${item.DonationImage}`}
                                      style={{
                                        width: "180px",
                                        height: "120px",
                                      }}
                                    ></img>
                                  </td>
                                  <td>{item.DonationLink}</td>
                                  <td>
                                    <FcDonate
                                      style={{ fontSize: "35px" }}
                                      onClick={() => ViewDonation(item)}
                                    />
                                  </td>
                                  <td style={{ fontSize: "20px" }}>
                                    <BiEdit
                                      style={{ color: "#ff7d26" }}
                                      onClick={() => {
                                        setshow1(true);
                                        setEditData(item);
                                      }}
                                    />
                                    /
                                    <AiFillDelete
                                      style={{ color: "red" }}
                                      onClick={() => DeleteCategory(item._id)}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    animation={true}
                    style={{ marginTop: "5%" }}
                  >
                    <Modal.Header></Modal.Header>
                    <div class="col-md-12" style={{ margin: "2% 4%" }}>
                      <input
                        type="text"
                        placeholder="Donation Title here..."
                        style={{
                          padding: "10px",
                          width: "90%",
                          border: "1px solid #ff7d26",
                          marginBottom: "2%",
                        }}
                        value={DonationTitle}
                        onChange={(e) => {
                          setDonationTitle(e.target.value);
                        }}
                      />

                      <input
                        type="text"
                        placeholder="Donation Link here..."
                        style={{
                          padding: "10px",
                          width: "90%",
                          marginBottom: "2%",
                          border: "1px solid #ff7d26",
                        }}
                        value={DonationLink}
                        onChange={(e) => {
                          setSubCategoryImage(e.target.value);
                        }}
                      />

                      {/* <input
                      type="text"
                      placeholder="Donation Collected here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    /> */}

                      {/* <input
                      type="text"
                      placeholder="SubCategoryLink here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={SubCategoryLink}
                      onChange={(e) => {
                        setSubCategoryLink(e.target.value);
                      }}
                    /> */}

                      {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                    </div>
                    <div class="add-list-media-wrap">
                      <div class="listsearch-input-item fl-wrap">
                        <div class="fuzone">
                          <form>
                            <div class="fu-text">
                              <span>
                                {!DonationImage ? (
                                  <FaImages
                                    style={{
                                      fontSize: "70px",
                                      color: "#ffcc33",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(DonationImage)}
                                    alt="Thumb"
                                    style={{
                                      width: "25%",
                                      height: "76px",
                                      paddingTop: "1%",
                                      paddingBottom: "1%",
                                    }}
                                  ></img>
                                )}
                                Click here or drop files to upload
                              </span>
                              <div class="photoUpload-files fl-wrap"></div>
                            </div>
                            <input
                              type="file"
                              name="file"
                              id="upload1"
                              accept="image/*"
                              onChange={(e) =>
                                setDonationImage(e.target.files[0])
                              }
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer
                      style={{
                        justifyContent: "space-between",
                        padding: "10px 20px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ffcc33",
                          border: "none",
                          width: "120px",
                        }}
                        onClick={handleClose}
                      >
                        CANCEL
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#ff7d26",
                          border: "none",
                          width: "180px",
                        }}
                        onClick={AddCategory}
                      >
                        ADD DONATE
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={show1}
                    onHide={handleClose1}
                    animation={true}
                    style={{ marginTop: "5%" }}
                  >
                    <Modal.Header></Modal.Header>
                    <div class="col-md-12" style={{ margin: "2% 4%" }}>
                      <input
                        type="text"
                        placeholder={EditData?.DonationTitle}
                        style={{
                          padding: "10px",
                          width: "90%",
                          border: "1px solid #ff7d26",
                          marginBottom: "2%",
                        }}
                        value={DonationTitle1}
                        onChange={(e) => {
                          setDonationTitle1(e.target.value);
                        }}
                      />

                      <input
                        type="text"
                        placeholder={EditData?.DonationLink}
                        style={{
                          padding: "10px",
                          width: "90%",
                          marginBottom: "2%",
                          border: "1px solid #ff7d26",
                        }}
                        value={DonationLink1}
                        onChange={(e) => {
                          setSubCategoryImage1(e.target.value);
                        }}
                      />

                      {/* <input
                      type="text"
                      placeholder="Donation Collected here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={Description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    /> */}

                      {/* <input
                      type="text"
                      placeholder="SubCategoryLink here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        marginBottom: "2%",
                        border: "1px solid #ff7d26",
                      }}
                      value={SubCategoryLink}
                      onChange={(e) => {
                        setSubCategoryLink(e.target.value);
                      }}
                    /> */}

                      {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                    </div>
                    <div class="add-list-media-wrap">
                      <div class="listsearch-input-item fl-wrap">
                        <div class="fuzone">
                          <form>
                            <div class="fu-text">
                              <span>
                                {!DonationImage1 ? (
                                  <FaImages
                                    style={{
                                      fontSize: "70px",
                                      color: "#ffcc33",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(DonationImage1)}
                                    alt="Thumb"
                                    style={{
                                      width: "25%",
                                      height: "76px",
                                      paddingTop: "1%",
                                      paddingBottom: "1%",
                                    }}
                                  ></img>
                                )}
                                Click here or drop files to upload
                              </span>
                              <div class="photoUpload-files fl-wrap"></div>
                            </div>
                            <input
                              type="file"
                              name="file"
                              id="upload1"
                              accept="image/*"
                              onChange={(e) =>
                                setDonationImage1(e.target.files[0])
                              }
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                    <Modal.Footer
                      style={{
                        justifyContent: "space-between",
                        padding: "10px 20px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ffcc33",
                          border: "none",
                          width: "120px",
                        }}
                        onClick={handleClose1}
                      >
                        CANCEL
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#ff7d26",
                          border: "none",
                          width: "180px",
                        }}
                        onClick={EditDonate}
                      >
                        UPDATE DONATE
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {ViewDonationCollected ? null : (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      )}
    </div>
  );
}

export default Add_Donate;
