// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
} from "reactstrap";

import "./Directory.css";

function BuySell() {
  const [dataSource, setdataSource] = useState([]);

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getBuySell");
    if (res.status === 200) {
      // console.log("AllCoupon===>", res);
      setdataSource(res.data.BuySellInfo);
    }
  };
  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            BUY & SELL
          </h6>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="EloetableOrder">
              <div class="card-body">
                <div
                  class="row"
                  style={{
                    padding: "10px 20px",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table
                      class="table table align-middle table-nowrap"
                      style={{ width: "96%", margin: "2%" }}
                    >
                      <thead
                        style={{ backgroundColor: "#ff7d26", color: "white" }}
                      >
                        <tr style={{ padding: "10px" }}>
                          <th
                            tabindex="0"
                            aria-label="Phone / Email sortable"
                            class="sortable"
                          >
                            USER NAME
                            <span class="order">
                              <span class="dropdown">
                                <span class="caret"></span>
                              </span>
                              <span class="dropup">
                                <span class="caret"></span>
                              </span>
                            </span>
                          </th>

                          <th>MOBILE NO</th>
                          <th>EMAIL</th>
                          <th>NOTE</th>
                          <th>BUY OR SELL</th>

                        </tr>
                      </thead>
                      <tbody>
                        {dataSource.map((item) => {
                          return (
                            <tr>
                              <td>{item.Username}</td>
                              <td>{item.Phonenumber}</td>
                              <td>{item.Email}</td>
                              <td>{item.Note}</td>
                              <td>{item.Buy_Sell}</td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuySell;
