import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import OwlCarousel from "react-owl-carousel";
import { AiFillPlayCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import axios from "axios";

function MdNews() {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [dataSource, setdataSource] = useState([]);

  useEffect(() => {
    AllPartners();
    AllPartners1();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getadvertisement"
    );
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.AdvertisementInfo);
    }
  };

  const [dataSource1, setdataSource1] = useState([]);

  const AllPartners1 = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getAdvertiseCategory"
    );
    if (res.status === 200) {
      // console.log(res);
      setdataSource1(res.data.AdvertisementCategoryInfo);
    }
  };

  const [SelectedAdd, setSelectedAdd] = useState({});
  const SetAdvertise = (item) => {
    setSelectedAdd(item);
    setShow(true);
  };
  return (
    <>
      <div className="old-0">
        <div className="press">
          <h5 className="pb-2" style={{ color: "#ffa300" }}>
            {/* Margdarshak News */}
          </h5>
          <div className="wraper-0  pb-2">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={7}
              autoplay={true}
              dots={false}
              nav={false}
              items={2}
              responsive={responsive}
            >
              {dataSource?.map((item) => {
                return (
                  <div class="item" onClick={() => SetAdvertise(item)}>
                    <a style={{ color: "black" }}>
                      <div className="list-3">
                        <div className="list-30">
                          <div className="">
                            <div className="entry-title-0">
                              <h5>{item.AddTitle}</h5>
                            </div>
                            <div className="her-re">
                              {/* <AiFillPlayCircle /> */}
                            </div>

                            <figcaption>
                              <img
                                src={`https://maargdarshak.in/Advertisement/${item.AddImg}`}
                                alt="free"
                                style={{ width: "100%", height: "151px" }}
                              />
                            </figcaption>
                          </div>
                          <p>{item.SubTitle}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}

              {/* <div class="item">
                <a style={{ color: "black" }}>
                  <div className="list-3">
                    <div className="list-30">
                      <div className="">
                        <div className="entry-title-0">
                          <h5>Advertisments</h5>
                        </div>
                        <div className="her-re">
                          <AiFillPlayCircle />
                        </div>
           
                        <figcaption>
                          <img
                            src="/image/Bus-2.webp"
                            alt="free"
                            style={{ width: "100%", height: "151px" }}
                          />
                        </figcaption>
                      </div>
                      <p>Advertise your Business</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="item">
                <a style={{ color: "black" }}>
                  <div className="list-3">
                    <div className="list-30">
                      <div className="">
                        <div className="entry-title-0">
                          <h5>Resource Requirments</h5>
                        </div>
                        <div className="her-re">
                          <AiFillPlayCircle onClick={handleShow1} />
                        </div>
                        <figcaption>
                          <img
                            src="/image/Bus-3.webp"
                            alt="free"
                            style={{ width: "100%", height: "151px" }}
                          />
                        </figcaption>
                      </div>
                      <p>Local demands & supply</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="item">
                <a style={{ color: "black" }}>
                  <div className="list-3">
                    <div className="list-30">
                      <div className="">
                        <div className="entry-title-0">
                          <h5>Business</h5>
                        </div>
                        <div className="her-re">
                          <AiFillPlayCircle onClick={handleShow2} />
                        </div>
                        <figcaption>
                          <img
                            src="/image/Bus-1.webp"
                            alt="free"
                            style={{ width: "100%", height: "151px" }}
                          />
                        </figcaption>
                      </div>
                      <p>Business availability</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="item">
                <a style={{ color: "black" }}>
                  <div className="list-3">
                    <div className="list-30">
                      <div className="">
                        <div className="entry-title-0">
                          <h5>Announcement</h5>
                        </div>
                        <div className="her-re">
                          <AiFillPlayCircle onClick={handleShow3} />
                        </div>
                        <figcaption>
                          <img
                            src="/image/high.avif"
                            alt="free"
                            style={{ width: "100%", height: "151px" }}
                          />
                        </figcaption>
                      </div>
                      <p>Maargdarshak News</p>
                    </div>
                  </div>
                </a>
              </div> */}
            </OwlCarousel>
          </div>
          <div></div>
        </div>
      </div>
      {/* Modal */}

      <div className="fd-0">
        <Modal className="vkd" show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <div className="herp-0">
              <ul className="kk-0">
                {dataSource1
                  .filter((data) => data.CategoryType == SelectedAdd.AddTitle)
                  .map((item) => {
                    return <li>{item.CategoryName}</li>;
                  })}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </div>

      {/* Modal */}

      {/* Modal-1 */}
      <div className="fd-0">
        <Modal className="vkd" show={show1} onHide={handleClose1}>
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <div className="herp-0">
              <ul className="kk-0">
                <li>IOS Developer</li>
                <li>Call Center Agents</li>
                <li>District represtatives</li>
                <li>Office Boys</li>
                <li>React Native Developers</li>
                <li>Ui Developer</li>
                <li>Teachers</li>
                <li>Insurance Agents</li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      {/* Modal-1 */}

      {/* Modal-2 */}
      <div className="fd-0">
        <Modal className="vkd" show={show2} onHide={handleClose2}>
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <div className="herp-0">
              <ul className="kk-0">
                <li>All Insurance Services</li>
                <li>Application Development</li>
                <li>Traning Academies</li>
                <li>Employment Requirments</li>
                <li>Home Solutions</li>
                <li>Partner agencies</li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        {/* Modal-2 */}
      </div>

      {/* Modal-3 */}
      <div className="fd-0">
        <Modal className="vkd" show={show3} onHide={handleClose3}>
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <div className="herp-0">
              <ul className="kk-0">
                <li>We are live on Maargdarshak Digitals Platform </li>
                <li>We are hiring districts represtatives</li>
                <li>
                  Work in progress with local Government and private partners
                </li>
                <li>Email to joins</li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        {/* Modal-2 */}
      </div>
    </>
  );
}

export default MdNews;
