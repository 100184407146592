import React, { useEffect, useState } from "react";
import "./register.scoped.scss";
import IconButton from "@mui/material/IconButton";
import { BiCamera } from "react-icons/bi";
import { DatePicker } from "rsuite";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { State } from "country-state-city";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
function Register() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow(true);
  const formdata = new FormData();
  const [staticMobile, setstaicMobile] = useState("");
  const [username, setusername] = useState();
  const [email, setemail] = useState();
  const [number, setnumber] = useState();
  const [age, setage] = useState();
  const [Address, setAddress] = useState();
  const [pincode1, setpincode1] = useState();
  const [pincode2, setpincode2] = useState();
  const [password, setpassword] = useState();
  const [skill, setskill] = useState();
  const [interest, setinterest] = useState();
  const [guidance, setguidance] = useState();
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [fromtime, setfromtime] = useState();
  const [totime, settotime] = useState();
  const [role, setrole] = useState();
  const [education, seteducation] = useState();
  const [Cv, setCv] = useState();
  const [Nation, setNation] = useState();



  const [Partnername, setPartnername] = useState();
  const [Organisationname, setOrganisationname] = useState();
  const [Email, setEmail] = useState();
  const [phonenumber, setphonenumber] = useState();
  const [partnerAddress, setpartnerAddress] = useState();
  const [partnerpassword, setpartnerpassword] = useState();
  const [ProfileImage, setProfileImage] = useState();
  const [PartnerProfileImage, setPartnerProfileImage] = useState();
  const [education1, seteducation1] = useState();
  const [PCv, setPCv] = useState();

  const history = useNavigate();

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var mobilevalid = /^[6-9][0-9]{9}$/;

  const addPartnerUser = async (e) => {
    e.preventDefault();
    if (!Partnername) {
      alert("Enter Partner name");
      return;
    }
    if (!Organisationname) {
      alert("Enter Organisation Name");
      return;
    }
    if (!phonenumber) {
      alert("Enter Contact Number");
      return;
    }
    if (phonenumber.length != 10) {
      alert("Enter Contact Number should be 10 digits");
      return;
    }
    if (!phonenumber.match(mobilevalid)) {
      alert("Enter Valid Contact Number");
      return;
    }
    if (!Email) {
      alert("Enter email Id");
      return;
    }
    if (!Email.match(validRegex)) {
      alert("Enter Valid Email-Id");
      return;
    }
    if (!partnerAddress) {
      alert("Enter Address");
      return;
    }
    if (!partnerpassword) {
      alert("Enter Password");
      return;
    }

    if (partnerpassword.length < 4) {
      alert("Password must be more than 3 digits");
      return;
    }
    try {
      const config = {
        url: "/user/sendOtpForPartner",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { mobile: phonenumber, Email: Email },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          setstaicMobile(phonenumber);
          handleShow();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };

  const addUser = async (e) => {
    e.preventDefault();
    if (!username) {
      alert("Enter Username");
      return;
    }
    if (!email) {
      alert("Enter email Id");
      return;
    }
    if (!email.match(validRegex)) {
      alert("Enter Valid Email-Id");
      return;
    }
    if (!number) {
      alert("Enter Contact Number");
      return;
    }
    if (number.length != 10) {
      alert("Enter Contact Number should be 10 digits");
      return;
    }
    if (!number.match(mobilevalid)) {
      alert("Enter Valid Contact Number");
      return;
    }
    if (!age) {
      alert("Enter Age");
      return;
    }
    if (Number(age) < 0) {
      alert("Age is not less than 0");
      return;
    }
    if (!Address) {
      alert("Enter Address");
      return;
    }
    if (!pincode1) {
      alert("Enter Pincode");
      return;
    }
    if (pincode1.length < 6 || pincode1.length > 6) {
      alert("Enter Pincode should be 6 digits");
      return;
    }
    if (!password) {
      alert("Enter Password");
      return;
    }
    if (password.length < 4) {
      alert("Password must be more than 3 digits");
      return;
    }

    if (!skill) {
      alert("Enter Skill");
      return;
    }
    if (!interest) {
      alert("Enter Interest");
      return;
    }
    if (!guidance) {
      alert("Enter Guidance");
      return;
    }
    if (!role) {
      alert("Select Role");
      return;
    }

    try {
      const config = {
        url: "/user/sendOtpForUser11",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { mobile: number, email: email },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          setstaicMobile(number);
          handleShow();
          // alert("Seccessfully send otp")
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };

  const [UserRegister, setUserRegister] = useState(true);

  const ChangeUserRegister1 = () => {
    setUserRegister(true);
    setPartnername("");
    setOrganisationname("");
    setEmail("");
    setphonenumber("");
    setpartnerAddress("");
    setpartnerpassword("");
  };

  const ChangeUserRegister2 = () => {
    setUserRegister(false);
    setusername("");
    setemail("");
    setnumber("");
    setage("");
    setAddress("");
    setpincode1("");
    setpassword("");
    setskill("");
    setinterest("");
    setguidance("");
    setfromdate("");
    settodate("");
    setfromtime("");
    settotime("");
    setrole("");
  };

  const optionList = [];

  const [dataSource, setdataSource] = useState([]);
  if (dataSource.length > 0) {
    for (let i = 0; i < dataSource.length; i++) {
      optionList.push({
        value: dataSource[i]?.pincode,
        label: dataSource[i]?.pincode,
      });
    }
  }
  const resendOtp = async () => {
    if (UserRegister == true) {
      try {
        const config = {
          url: "/user/sendOtpForUser11",
          method: "post",
          baseURL: "https://maargdarshak.in/api",
          hearder: { "content-type": "application/json" },

          data: { mobile: number, email: email },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            handleShow();
            alert("Seccessfully resend otp");
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        console.log(error.response);
        alert(error.response.data.error);
      }
    } else {
      try {
        const config = {
          url: "/user/sendOtpForPartner",
          method: "post",
          baseURL: "https://maargdarshak.in/api",
          hearder: { "content-type": "application/json" },

          data: { mobile: phonenumber, Email: Email },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            alert("Successfully resend otp");
            handleShow();
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        console.log(error.response);
        alert(error.response.data.error);
      }
    }
  };
  const [otp1, setotp1] = useState("");
  const [otp2, setotp2] = useState("");
  const [otp3, setotp3] = useState("");
  const [otp4, setotp4] = useState("");
  const [otp5, setotp5] = useState("");
  const [otp6, setotp6] = useState("");

  const registerUser = async () => {
    formdata.append("Username", username);
    formdata.append("email", email);
    formdata.append("Mobile", number);
    formdata.append("Age", age);
    formdata.append("Address", Address);
    formdata.append("PrimaryPincode", pincode1.value);
    formdata.append("SecondaryPincode", pincode2);
    formdata.append("password", password);
    formdata.append("skill", skill);
    formdata.append("interest", interest);
    formdata.append("needguidance", guidance);
    formdata.append("fromdate", fromdate);
    formdata.append("todate", todate);
    formdata.append("fromtime", fromtime);
    formdata.append("totime", totime);
    formdata.append("role", role);
    formdata.append("Education", education);
    formdata.append("check", "User");
    formdata.append("UserImage", ProfileImage);
    formdata.append("Cv", Cv);

    try {
      const config = {
        url: "/user/addUser",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          setstaicMobile(number);
          window.location.assign("/login");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };
  const registerPartner = async () => {
    formdata.append("Username", Partnername);
    formdata.append("Email", Email);
    formdata.append("Organisationname", Organisationname);
    formdata.append("phonenumber", phonenumber);
    formdata.append("Address", partnerAddress);
    formdata.append("password", partnerpassword);
    formdata.append("UserImage", PartnerProfileImage);
    formdata.append("Education", education1);
    formdata.append("check", "Partner");
    formdata.append("PCv", PCv);

    try {
      const config = {
        url: "/user/addPartner",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          setstaicMobile(number);
          window.location.assign("/Partnerlogin");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
      alert(error.response.data.error);
    }
  };

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const verifyOtp = async () => {
    try {
      if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6)
        return alert("Please enter valid otp");
      try {
        const config = {
          url: "/user/verifyotpUser",
          method: "post",
          baseURL: "https://maargdarshak.in/api",
          hearder: { "content-type": "application/json" },

          data: {
            mobile: staticMobile,
            otp: `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert("Successfully register");
          if (UserRegister == true) {
            return registerUser();
          } else {
            return registerPartner();
          }
        } else {
          alert("Something Wrong");
        }
      } catch (error) {
        console.log(error.response);
        alert(error.response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getallpincode");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.pincode);
    }
  };

  console.log(pincode1, "fdfdf");

  const handleSelect = (data) => {
    setpincode1(data);
  };

  return (
    <>
      <div
        className=""
        style={
          {
            // backgroundImage:"url('./image/bg.webp')"
          }
        }
      >
        <div class="form_wrapper">
          {" "}
          <div style={{ display: "flex" }}>
            <button
              style={{
                width: "48%",
                backgroundColor: "#0060aa",
                color: "white",
                fontSize: "18px",
                margin: "0% 1%",
                padding: "5px 0px",
              }}
              onClick={() => ChangeUserRegister1()}
            >
              User-Registration
            </button>
            <button
              style={{
                width: "48%",
                backgroundColor: "#0060aa",
                color: "white",
                margin: "0% 1%",

                fontSize: "18px",
              }}
              onClick={() => ChangeUserRegister2()}
            >
              Partner-Registration
            </button>
          </div>
          {UserRegister ? (
            <div class="form_container">
              <div class="title_container">
                <h2>User Registration </h2>
                <figcaption style={{ position: "relative" }}>
                  {!ProfileImage ? (
                    <img
                      src="/image/avatar.png"
                      alt="avatar"
                      style={{
                        width: "151px",
                        height: "151px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(ProfileImage)}
                      alt="avatar"
                      style={{
                        width: "151px",
                        height: "151px",
                        borderRadius: "50%",
                      }}
                    />
                  )}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="file"
                      id="upload1"
                      onChange={(e) => setProfileImage(e.target.files[0])}
                    />
                    <BiCamera />
                  </IconButton>
                </figcaption>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-user"></i>
                      </span>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        required
                        value={username}
                        onChange={(e) => {
                          setusername(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        name="age"
                        placeholder="Email"
                        value={email}
                        required
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Mobile No"
                        value={number}
                        maxLength={10}
                        required
                        onKeyPress={(event) => {
                          if (!/[0-9]|\./.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setnumber(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-user-secret" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="age"
                        value={age}
                        onKeyPress={(event) => {
                          if (!/[0-9]|\./.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={2}
                        onChange={(e) => {
                          setage(e.target.value);
                        }}
                        placeholder="Age"
                        required
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-user-secret" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="Address"
                        placeholder="Address"
                        required
                        value={Address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>

                    <div class="input_field">
                      {" "}
                     
                      <CountrySelect
                  className="form-control"
                  onChange={(e) => {
                    setNation(e.name);
                  }}
                  placeHolder="Select country"
                />


                    </div>
                    {Nation?.toUpperCase() == "INDIA" ?   <>   <div class="input_field"><span>
                        <i class="fa fa-user-secret" aria-hidden="true"></i>
                      </span>
                      <Select
                        options={optionList}
                        placeholder="Select Pincode"
                        value={pincode1}
                        onChange={handleSelect}
                        isSearchable={true}
                        // isMulti
                      />
                    </div>
                      </> : null}
                  
                      {" "}
                   
                    {/* <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-pencil"></i>
                      </span>
                      <input
                        type="text"
                        name="pincode"
                        placeholder="Pincode"
                        required
                        onChange={(e) => {
                          setpincode2(e.target.value);
                        }}
                      />
                    </div> */}
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-book" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Education"
                        required
                        value={education}
                        onChange={(e) => {
                          seteducation(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Skill"
                        required
                        value={skill}
                        onChange={(e) => {
                          setskill(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Interest"
                        required
                        value={interest}
                        onChange={(e) => {
                          setinterest(e.target.value);
                        }}
                      />
                    </div>

                    <span>Upload Cv</span>
                    <div class="input_field">
                      {" "}
                      <input
                      
                        type="file"
                        name="Cv"
                        placeholder="Interest"
                        required
                        onChange={(e) => {
                          setCv(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="What you need guidance on ?"
                        required
                        value={guidance}
                        onChange={(e) => {
                          setguidance(e.target.value);
                        }}
                      />
                    </div>

                    {/* <div class="row clearfix">
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="name" placeholder="First Name" />
              </div>
            </div>
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="name" placeholder="Last Name" required />
              </div>
            </div>
          </div> */}
                    {/* <div class="input_field radio_option">
              <input type="radio" name="radiogroup1" id="rd1"/>
              <label for="rd1">Male</label>
              <input type="radio" name="radiogroup1" id="rd2"/>
              <label for="rd2">Female</label>
              </div> */}
                    <div class="input_field select_option">
                      <select
                        onChange={(e) => {
                          setrole(e.target.value);
                        }}
                      >
                        <option value="">Role</option>
                        <option value="Student">Student</option>
                        <option value="Trader">Trader</option>
                        <option value="Buyer-Seller">Buyer-Seller</option>
                        <option value="Teacher">Teacher</option>
                        <option value="Labour">Labour</option>
                        <option value="Artist">Artist</option>
                        <option value="Engineer">Engineer</option>
                        <option value="College Owner">College Owner</option>
                        <option value="School Owner">School Owner</option>
                        <option value="Part Timer">Part Timer</option>
                        <option value="Coder">Coder</option>
                        <option value="Chef">Chef</option>
                        <option value="Cook">Cook</option>
                        <option value="Internship">Internship</option>
                        <option value="Driver">Driver</option>
                        <option value="Professor">Professor</option>
                        <option value="Hacker">Hacker</option>
                        <option value="Tester">Tester</option>
                        <option value="Project manager">Project manager</option>
                        <option value="Others">Others</option>
                      </select>
                      <div class="select_arrow"></div>
                    </div>

                    {role == "Internship" || role == "Part Timer" ? (
                      <>
                        <div className="row" style={{ paddingLeft: "20px" }}>
                          <div className="col-md-6">
                            <div className="input_field">
                              <h6>From date</h6>

                              <input
                                type="date"
                                name="mobile"
                                // placeholder="What you need guidance on ?"
                                required
                                onChange={(e) => {
                                  setfromdate(e.target.value);
                                }}
                              />
                              {/* <DatePicker
                            appearance="default"
                            size="lg"
                            format="yyyy-MM-dd HH:mm:ss"
                            onChange={(e) => {
                              setfromdate(e.target.value);
                            }}
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: 240 }}
                          /> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input_field">
                              <h6>To date</h6>
                              <input
                                type="date"
                                name="mobile"
                                // placeholder="What you need guidance on ?"
                                required
                                onChange={(e) => {
                                  settodate(e.target.value);
                                }}
                              />
                              {/* <DatePicker
                            appearance="default"
                            size="lg"
                            format="yyyy-MM-dd HH:mm:ss"
                            onChange={(e) => {
                              settodate(e.target.value);
                            }}
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: 240 }}
                          /> */}
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ paddingLeft: "20px" }}>
                          <div className="col-md-6">
                            <div className="input_field">
                              <h6>From Time</h6>

                              <input
                                type="time"
                                name="mobile"
                                // placeholder="What you need guidance on ?"
                                required
                                onChange={(e) => {
                                  setfromtime(e.target.value);
                                }}
                              />
                              {/* <DatePicker
                            appearance="default"
                            size="lg"
                            format="yyyy-MM-dd HH:mm:ss"
                            onChange={(e) => {
                              setfromdate(e.target.value);
                            }}
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: 240 }}
                          /> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input_field">
                              <h6>To Time</h6>
                              <input
                                type="time"
                                name="mobile"
                                // placeholder="What you need guidance on ?"
                                required
                                onChange={(e) => {
                                  settotime(e.target.value);
                                }}
                              />
                              {/* <DatePicker
                            appearance="default"
                            size="lg"
                            format="yyyy-MM-dd HH:mm:ss"
                            onChange={(e) => {
                              settodate(e.target.value);
                            }}
                            ranges={[
                              {
                                label: "Now",
                                value: new Date(),
                              },
                            ]}
                            style={{ width: 240 }}
                          /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* <div class="input_field checkbox_option">
              <input type="checkbox" id="cb1"/>
          <label for="cb1">I agree with terms and conditions</label>
            </div>
            <div class="input_field checkbox_option">
              <input type="checkbox" id="cb2"/>
          <label for="cb2">I want to receive the newsletter</label>
            </div> */}
                    <input
                      class="button"
                      type="submit"
                      value="Register"
                      onClick={(e) => addUser(e)}
                    />
                    <div>
                      Already have an account?<a href="/login">Login</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div class="form_container">
              <div class="title_container">
                <h2>Partner Registration </h2>
                <figcaption style={{ position: "relative" }}>
                  {!PartnerProfileImage ? (
                    <img
                      src="/image/avatar.png"
                      alt="avatar"
                      style={{
                        width: "151px",
                        height: "151px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(PartnerProfileImage)}
                      alt="avatar"
                      style={{
                        width: "151px",
                        height: "151px",
                        borderRadius: "50%",
                      }}
                    />
                  )}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="file"
                      id="upload2"
                      onChange={(e) =>
                        setPartnerProfileImage(e.target.files[0])
                      }
                    />
                    <BiCamera />
                  </IconButton>
                </figcaption>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-user"></i>
                      </span>
                      <input
                        type="text"
                        name="name1"
                        placeholder="Partner Name"
                        value={Partnername}
                        required
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setPartnername(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-building" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="age"
                        value={Organisationname}
                        placeholder="Organisation Name"
                        required
                        onChange={(e) => {
                          setOrganisationname(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-book" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Education"
                        required
                        value={education1}
                        onChange={(e) => {
                          seteducation1(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-phone"></i>
                      </span>
                      <input
                        type="text"
                        name="mobile"
                        value={phonenumber}
                        placeholder="Mobile No"
                        maxLength={10}
                        required
                        onKeyPress={(event) => {
                          if (!/[0-9]|\./.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setphonenumber(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="age"
                        value={Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        required
                      />
                    </div>

                    <span>Upload Cv</span>
                    
                    <div class="input_field">
                      {" "}
                      
                      <input
                        type="file"
                        name="Cv"
                        placeholder="Interest"
                        required
                        value={PCv}
                        onChange={(e) => {
                          setPCv(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-user-secret" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="Address"
                        placeholder="Address"
                        required
                        value={partnerAddress}
                        onChange={(e) => {
                          setpartnerAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa fa-lock" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        name="Address"
                        placeholder="Password"
                        required
                        // maxLength={8}
                        value={partnerpassword}
                        onChange={(e) => {
                          setpartnerpassword(e.target.value);
                        }}
                      />
                    </div>

                    {/* <div class="row clearfix">
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="name" placeholder="First Name" />
              </div>
            </div>
            <div class="col_half">
              <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                <input type="text" name="name" placeholder="Last Name" required />
              </div>
            </div>
          </div> */}
                    {/* <div class="input_field radio_option">
              <input type="radio" name="radiogroup1" id="rd1"/>
              <label for="rd1">Male</label>
              <input type="radio" name="radiogroup1" id="rd2"/>
              <label for="rd2">Female</label>
              </div> */}

                    {/* <div class="input_field checkbox_option">
              <input type="checkbox" id="cb1"/>
          <label for="cb1">I agree with terms and conditions</label>
            </div>
            <div class="input_field checkbox_option">
              <input type="checkbox" id="cb2"/>
          <label for="cb2">I want to receive the newsletter</label>
            </div> */}
                    <input
                      class="button"
                      type="submit"
                      value="Register"
                      onClick={(e) => addPartnerUser(e)}
                    />
                    <div>
                      Already have an account?<a href="/Partnerlogin">Login</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>
            <div class="title_container-0">
              <h2>Please enter the OTP sent to your Mobile</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div class="form_wrapper">
              {/* <div class="form_container"> */}

              <div class="row clearfix">
                <div class="">
                  <div style={{ display: "flex", gap: "11px" }}>
                    <div class="input_field">
                      <input
                        name="field-1"
                        type="text"
                        onChange={(e) => {
                          setotp1(e.target.value);
                          handleChange(e);
                        }}
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                    <div class="input_field">
                      <input
                        type="text"
                        name="field-2"
                        onChange={(e) => {
                          setotp2(e.target.value);
                          handleChange(e);
                        }}
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                    <div class="input_field">
                      <input
                        name="field-3"
                        onChange={(e) => {
                          setotp3(e.target.value);
                          handleChange(e);
                        }}
                        type="text"
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                    <div class="input_field">
                      <input
                        name="field-4"
                        onChange={(e) => {
                          setotp4(e.target.value);
                          handleChange(e);
                        }}
                        type="text"
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                    <div class="input_field">
                      <input
                        name="field-5"
                        onChange={(e) => {
                          setotp5(e.target.value);
                          handleChange(e);
                        }}
                        type="text"
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                    <div class="input_field">
                      <input
                        name="field-6"
                        onChange={(e) => {
                          setotp6(e.target.value);
                          handleChange(e);
                        }}
                        type="text"
                        autoComplete="off"
                        className="text-center otpInput"
                        tabIndex="1"
                        maxLength="1"
                        style={{ padding: "unset" }}
                      />
                    </div>
                  </div>
                  <input
                    class="button"
                    type="submit"
                    onClick={() => verifyOtp()}
                    value=" Verify OTP"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={resendOtp}>
            Resend
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Register;
