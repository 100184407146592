// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import "./Directory.css";
import ReactPaginate from "react-paginate";

function Add_AdvertisementCategory() {
  const [dataSource, setdataSource] = useState([]);
  const [dataSource1, setdataSource1] = useState([]);

  const formdata = new FormData();

  useEffect(() => {
    AllPartners();
    AllAdvertiseList();
  }, []);

  const AllAdvertiseList = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getadvertisement"
    );
    if (res.status === 200) {
      // console.log(res);
      setdataSource1(res.data.AdvertisementInfo);
    }
  };

  const AllPartners = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getAdvertiseCategory"
    );
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.AdvertisementCategoryInfo);
    }
  };

  const [CategoryName, setCategoryName] = useState();
  const [CategoryLink, setCategoryLink] = useState("");
  const [CategoryType, setCategoryType] = useState();
  const [CategoryImage, setCategoryImage] = useState();
  const [show, setshow] = useState(false);

  const handleClose = () => {
    setshow(false);
  };

  const AddCategory = async (e) => {
    e.preventDefault();
    if (!CategoryName) {
      alert("Please Enter Category Name");
      return;
    }
    if (!CategoryType) {
      alert("Please Select Image");
      return;
    }
    formdata.append("CategoryName", CategoryName.toUpperCase());
    formdata.append("CategoryLink", CategoryLink);
    formdata.append("CategoryType", CategoryType);
    formdata.append("CategoryImage", CategoryImage);

    try {
      const config = {
        url: "/user/addAdvertiseCategory",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: formdata,
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Category Added");
          setshow(false);

          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 0px 20px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            ADVERTISE CATEGORY
          </h6>
          <button
            style={{
              backgroundColor: "#ff7d26",
              color: "white",
              fontWeight: "600",
              fontSize: "14px",
              padding: "10px 10px",
              borderRadius: "20px",
            }}
            onClick={() => setshow(true)}
          >
            +ADD ADVERTISE-CATEGORY
          </button>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="EloetableOrder">
              <div class="card-body">
                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table
                      class="table table align-middle table-nowrap"
                      style={{ width: "96%", margin: "2%" }}
                    >
                      <thead
                        style={{ backgroundColor: "#ff7d26", color: "white" }}
                      >
                        <tr style={{ padding: "10px", textAlign: "center" }}>
                          <th
                            tabindex="0"
                            aria-label="Phone / Email sortable"
                            class="sortable"
                          >
                            CATEGORY NAME{" "}
                            <span class="order">
                              <span class="dropdown">
                                <span class="caret"></span>
                              </span>
                              <span class="dropup">
                                <span class="caret"></span>
                              </span>
                            </span>
                          </th>

                          <th>CATEGORY IMAGE</th>
                          <th>CATEGORY TYPE</th>
                          <th>CATEGORY LINK</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSource
                          .slice(pagesVisited, pagesVisited + usersPerPage)
                          .map((item) => {
                            return (
                              <tr
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <td>{item.CategoryName}</td>
                                <td>
                                  <img
                                    src={`https://maargdarshak.in/CategoryImage/${item.CategoryImage}`}
                                    style={{ width: "180px", height: "120px" }}
                                  ></img>
                                </td>
                                <td>{item.CategoryType}</td>
                                <td>{item.CategoryLink}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  animation={true}
                  style={{ marginTop: "5%" }}
                >
                  <Modal.Header></Modal.Header>
                  <select
                    style={{
                      width: "90%",
                      padding: "10px 10px",
                      border: "1px solid #ff7d26",
                      margin: "0% 5% 2% 5%",
                    }}
                    onChange={(e) => {
                      setCategoryType(e.target.value);
                    }}
                  >
                    <option>Select Category</option>
                    {dataSource1.map((item) => {
                      return (
                        <option value={item.AddTitle}>{item.AddTitle}</option>
                      );
                    })}
                  </select>

                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Category Name here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={CategoryName}
                      onChange={(e) => {
                        setCategoryName(e.target.value);
                      }}
                    />
                    {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                  </div>
                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <br></br>
                    <input
                      type="text"
                      placeholder="Category Link here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={CategoryLink}
                      onChange={(e) => {
                        setCategoryLink(e.target.value);
                      }}
                    />
                    {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                  </div>
                  <div class="add-list-media-wrap">
                    <div class="listsearch-input-item fl-wrap">
                      <div class="fuzone">
                        <form>
                          <div class="fu-text">
                            <span>
                              {!CategoryImage ? (
                                <FaImages
                                  style={{ fontSize: "70px", color: "#ffcc33" }}
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(CategoryImage)}
                                  alt="Thumb"
                                  style={{
                                    width: "25%",
                                    height: "76px",
                                    paddingTop: "1%",
                                    paddingBottom: "1%",
                                  }}
                                ></img>
                              )}
                              Click here or drop files to upload
                            </span>
                            <div class="photoUpload-files fl-wrap"></div>
                          </div>
                          <input
                            type="file"
                            name="file"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) =>
                              setCategoryImage(e.target.files[0])
                            }
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <Modal.Footer
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ffcc33",
                        border: "none",
                        width: "120px",
                      }}
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>

                    <Button
                      style={{
                        backgroundColor: "#ff7d26",
                        border: "none",
                        width: "180px",
                      }}
                      onClick={AddCategory}
                    >
                      ADD CATEGORY
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
}

export default Add_AdvertisementCategory;
