import React, { useEffect, useState } from "react";
import News from "./News";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Card } from "react-bootstrap";
import Footer from "./Footer";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { Player, ControlBar, BigPlayButton } from "video-react";
import Slider from "react-slick";
import MdNews from "./MdNews";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Youth from "./Youth";
// import { useFormik } from "formik";
import { Country, State, City } from "country-state-city";
import { Select } from "antd";
import axios from "axios";
// import Select from "react-select";
import OwlCarousel from "react-owl-carousel";

import { GiReceiveMoney } from "react-icons/gi";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function Home() {
  const [country, setcountry] = useState("IN");
  const [state, setstate] = useState("KA");
  const CountryList = Country.getAllCountries();
  const StateList = State.getStatesOfCountry(country);
  const CityList = City.getCitiesOfState(country, state);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const UserType = JSON.parse(sessionStorage.getItem("userType"));

  const AllstateList = [];
  const AllCityList = [];

  if (country) {
    for (let i = 0; i < StateList.length; i++) {
      AllstateList.push({
        label: StateList[i].name,
      });
    }

    for (let i = 0; i < CityList.length; i++) {
      AllCityList.push({
        label: CityList[i].name,
      });
    }
  }

  const [Selectedstate, setSelectedstate] = useState();
  // console.log(Selectedstate, "seleced staet");
  // console.log(AllCityList, "all AllCityList list");

  const top100Films = [
    { label: 560056, year: 1994 },
    { label: 560067, year: 1994 },
    { label: 560896, year: 1994 },
    { label: 560890, year: 1994 },
    { label: 560089, year: 1994 },
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const responsive2 = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [done, setDone] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      //   fetch("https://jsonplaceholder.typicode.com/posts")
      //     .then((response) => response.json())
      //     .then((json) => {
      //       console.log(json);
      //       setData(json);
      setDone(true);
      // });
    }, 3000);
  }, []);

  const [Enquiry1, setEnquiry1] = useState();
  // console.log(Enquiry1, "ftdsftfedtyef");
  const SendEnquiry1 = async (e) => {
    e.preventDefault();

    if (Enquiry1) {
      try {
        const config = {
          url: "/user/addEnquiry",
          method: "post",
          baseURL: "https://maargdarshak.in/api",
          hearder: { "content-type": "application/json" },

          data: {
            UserId: user._id,

            Enquiry: Enquiry1,
          },
        };
        await axios(config).then(function (res) {
          if (res.status === 200) {
            // console.log(res.data);
            alert("Enquiry Send");
            window.location.reload();
          } else {
            alert("Something Wrong");
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    } else {
      alert("Please Enter Some Enquiry to Send");
    }
    e.preventDefault();
  };

  const [AllDonatedata, setAllDonatedata] = useState([]);
  useEffect(() => {
    AllDonate();
  }, []);

  const AllDonate = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getDonate");
    if (res.status === 200) {
      // console.log(res);
      setAllDonatedata(res.data.DonateInfo);
    }
  };

  const [VideoLink, setVideoLink] = useState([]);

  useEffect(() => {
    AllVideo();
  }, []);

  const AllVideo = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getVideo");
    if (res.status === 200) {
      // console.log(res);
      const data = res.data.VideoInfo.filter(
        (data) => data.Status === "Active"
      );
      setVideoLink(data);
    }
  };

  const [show1, setshow1] = useState(false);
  const [SelectedDonation, setSelectedDonation] = useState({});

  const OpenDonationModal = (item) => {
    setSelectedDonation(item);
    setshow1(true);
  };

  const handleClose1 = () => {
    setshow1(false);
  };

  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [phonenumber, setphonenumber] = useState();
  const [Donation, setDonation] = useState();

  const AddDonation = async (e) => {
    e.preventDefault();
    if (!name) {
      alert("Please Enter Name");
      return;
    }
    if (!phonenumber) {
      alert("Please Enter Contact Number");
      return;
    }
    if (phonenumber.length != 10) {
      alert(" Entered Contact Number should be 10 digits");
      return;
    }
    if (!email) {
      alert("Please Enter Email");
      return;
    }
    if (!Donation) {
      alert("Please Donation");
      return;
    }

    try {
      const config = {
        url: "/user/addDonationCollected",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: {
          Username: name,
          Donation: Donation,
          Email: email,
          phonenumber: phonenumber,
          DonationId: SelectedDonation._id,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Donation send");
          setname("");
          setemail("");
          setphonenumber("");
          setDonation("");
          setshow1(false);
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [dataSource, setdataSource] = useState([]);

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCategory");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.CategoryInfo);
    }
  };

  const [dataSource1, setdataSource1] = useState([]);

  useEffect(() => {
    AllPartners11();
  }, []);

  const AllPartners11 = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getAddText");
    if (res.status === 200) {
      // console.log(res);
      setdataSource1(res.data.AddTextInfo);
    }
  };

  console.log(dataSource1, "fffff");

  return (
    <>
      <div
        className=""
        style={{
          backgroundImage: "url('/image/hand-1.webp')",
          height: "auto",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="mfdmo  mb-1" role="presentation" onClick={handleClick}>
          <marquee
            class="marq"
            bgcolor="#0060aa"
            direction="left"
            // width="80%"
            scrollamount="6"
            loop=""
          >
            {dataSource1
              .filter((data) => data?.TextDisplayType == "SCROLLABLE-TEXT")
              .map((item) => {
                return (
                  <h6 style={{ color: "white", fontWeight: "500" }}>
                    {item?.Content}
                  </h6>
                );
              })}
          </marquee>
        </div>
        <div className="container-fluid">
          <div className="df-0-o">
            <div className="news">
              <div className="dfsdo">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="news-0">
                        <div
                          className="or-d-f"
                          style={{
                            backgroundImage: "url('/image/ab-0.webp')",
                            backgrounSize: "cover",
                            backgroundPosition: "center",
                            height: "auto",
                          }}
                        >
                          {dataSource1
                            .filter(
                              (data) => data?.TextDisplayType == "BLOCK_1-TEXT"
                            )
                            .map((item) => {
                              return item?.Content;
                            })}
                        </div>
                      </div>
                    </div>
                    <News />
                  </div>

                  <div className="col-md-5">
                    <div className="st-09">
                      <div className="news-0">
                        <div className="or-d-f dd">
                          {dataSource1
                            .filter(
                              (data) => data?.TextDisplayType == "BLOCK_2-TEXT"
                            )
                            .map((item) => {
                              return item?.Content;
                            })}
                        </div>
                      </div>
                      {/* mdNews */}
                      <MdNews />

                      {/* mdNews */}
                      {/* slider */}
                      {/* <div className="ap_ps">
                      <div className="  pb-2">
                        <Slider {...settings}>
                          <div>
                            <div className="news-p-0">
                              <div className="figa-09">
                                <figcaption className="nirma">
                                  <img
                                    src="/image/open-1.png"
                                    alt=""
                                    style={{ width: "70px", height: "60px" }}
                                  />
                                </figcaption>
                                <h6>Openings</h6>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="news-p-0">
                              <div className="figa-09">
                                <figcaption className="nirma">
                                  <img
                                    src="/image/open-2.png"
                                    alt=""
                                    style={{ width: "70px", height: "60px" }}
                                  />
                                </figcaption>
                                <h6>Requirments</h6>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="news-p-0">
                              <div className="figa-09">
                                <figcaption className="nirma">
                                  <img
                                    src="/image/open3.png"
                                    alt=""
                                    style={{ width: "70px", height: "60px" }}
                                  />
                                </figcaption>
                                <h6>Bussiness</h6>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="news-p-0">
                              <div className="figa-09">
                                <figcaption className="nirma">
                                  <img
                                    src="/image/open-4.png"
                                    alt=""
                                    style={{ width: "70px", height: "60px" }}
                                  />
                                </figcaption>
                                <h6>Advertisments</h6>
                              </div>
                            </div>
                          </div>

                          <div></div>
                        </Slider>
                      </div>
                    </div> */}

                      {/* slider */}

                      {/* ------------- */}
                      <div className="free-paid">
                        <div className="row">
                          <div className="col-md-12">
                            <Modal
                              show={show1}
                              onHide={handleClose1}
                              animation={true}
                              style={{ marginTop: "5%" }}
                            >
                              <Modal.Header>
                                <span
                                  style={{
                                    color: "grey",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {SelectedDonation.DonationTitle}
                                </span>
                              </Modal.Header>
                              <div
                                class="col-md-12"
                                style={{ margin: "2% 4%" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Name here..."
                                  style={{
                                    padding: "10px",
                                    width: "90%",
                                    border: "1px solid #ff7d26",
                                    marginBottom: "10px",
                                  }}
                                  value={name}
                                  onChange={(e) => {
                                    setname(e.target.value);
                                  }}
                                />

                                <input
                                  type="text"
                                  placeholder="Phone Number here..."
                                  style={{
                                    padding: "10px",
                                    width: "90%",
                                    marginBottom: "10px",
                                    border: "1px solid #ff7d26",
                                  }}
                                  value={phonenumber}
                                  onChange={(e) => {
                                    setphonenumber(e.target.value);
                                  }}
                                />

                                <input
                                  type="text"
                                  placeholder="Email here..."
                                  style={{
                                    padding: "10px",
                                    width: "90%",
                                    marginBottom: "10px",
                                    border: "1px solid #ff7d26",
                                  }}
                                  value={email}
                                  onChange={(e) => {
                                    setemail(e.target.value);
                                  }}
                                />

                                <input
                                  type="text"
                                  placeholder="Donation here..."
                                  style={{
                                    padding: "10px",
                                    width: "90%",
                                    marginBottom: "10px",
                                    border: "1px solid #ff7d26",
                                  }}
                                  value={Donation}
                                  onChange={(e) => {
                                    setDonation(e.target.value);
                                  }}
                                />
                              </div>

                              <Modal.Footer
                                style={{
                                  justifyContent: "space-between",
                                  padding: "10px 20px",
                                }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "#ffcc33",
                                    border: "none",
                                    width: "120px",
                                  }}
                                  onClick={handleClose1}
                                >
                                  CANCEL
                                </Button>

                                <Button
                                  style={{
                                    backgroundColor: "#ff7d26",
                                    border: "none",
                                    width: "180px",
                                  }}
                                  onClick={AddDonation}
                                >
                                  DONATE
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <div>
                              {done ? (
                                <div>
                                  {AllDonatedata.map((item) => {
                                    return (
                                      <div
                                        className="loader-mobile"
                                        style={{
                                          justifyContent: "center",
                                          display: "flex",
                                          width: "100%",
                                        }}
                                        onClick={() => OpenDonationModal(item)}
                                      >
                                        <div className="diff-pr-2 pt-2 pb-2">
                                          <div className="serv pb-2">
                                            <div className="some">
                                              <div className="paid-ser">
                                                <figcaption>
                                                  <img
                                                    src={`https://maargdarshak.in/Donate/${item.DonationImage}`}
                                                    alt=""
                                                    style={{
                                                      width: "70px",
                                                      height: "60px",
                                                    }}
                                                  />
                                                </figcaption>
                                              </div>
                                              <h5 style={{ color: "#ffa300" }}>
                                                {item.DonationTitle}
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {user ? (
                              <p className="pt-1" style={{ fontSize: "19px" }}>
                                {" "}
                                Please Leave a note as per your
                                Interest/Business in your Local Area
                              </p>
                            ) : null}
                            {UserType == "User" ? (
                              <div className="kfjd">
                                <div className="">
                                  <div style={{ display: "flex" }}>
                                    <textarea
                                      className="input_fields"
                                      placeholder="Note here..."
                                      rows="1"
                                      cols="55"
                                      onChange={(e) =>
                                        setEnquiry1(e.target.value)
                                      }
                                    ></textarea>
                                    <button
                                      style={{
                                        padding: "10px 16px",
                                        backgroundColor: "#ff7d26",
                                        color: "white",
                                        fontWeight: "bold",
                                        borderRadius: "0px 10px 10px 0px",
                                      }}
                                      onClick={SendEnquiry1}
                                    >
                                      SUBMIT
                                    </button>
                                  </div>

                                  {/* <div className="">
                                  <div
                                    className=""
                                    style={{ fontSize: "1rem" }}
                                  >
                                    Choose your location below
                                  </div>
                                  <div className="jgk-09">
                                    <div>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={top100Films}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Pin Code"
                                          />
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={AllCityList}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                          <TextField {...params} label="City" />
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={AllstateList}
                                        onChange={(event, value) =>
                                          console.log(value)
                                        }
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="State"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div> */}
                                </div>
                              </div>
                            ) : null}

                            {/* Youth */}

                            <div className="diff-pr-2 pt-2 pb-2">
                              <div className="serv pb-2">
                                <Youth />
                              </div>
                            </div>
                            {/*  */}

                            {/* labour & employment */}
                            {/* <div className="diff-pr-2 pt-2 pb-2">
                              <div className="serv pb-2">
                                <div className="some">
                                  <div className="paid-ser">
                                    <figcaption>
                                      <img
                                        src="/image/Labour.png"
                                        alt=""
                                        style={{
                                          width: "70px",
                                          height: "60px",
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <a href="https://labour.gov.in/">
                                    <h5 style={{ color: "#ffa300" }}>
                                      Labour & employment
                                    </h5>
                                  </a>
                                </div>
                              </div>
                            </div> */}
                            {/*  */}
                          </div>
                        </div>
                      </div>
                      {/* === */}

                      {/* Video */}
                      <div className="video">
                        <div className="vi-d-0">
                          <h5
                            className="pb-2 pt-3"
                            style={{ color: "#ffa300" }}
                          >
                            Video of the week
                          </h5>

                          <div className="">
                            {/* <Player
                          
                              //  poster="/image/poster.png"
                            src="https://youtu.be/nQWBhasY8xw">
                              <BigPlayButton position="center" />
                             
                            </Player> */}
                            {VideoLink.length > 1 ? (
                              <>
                                <OwlCarousel
                                  className="owl-theme"
                                  loop
                                  margin={7}
                                  autoplay={true}
                                  dots={false}
                                  nav={false}
                                  items={2}
                                  responsive={responsive2}
                                >
                                  {VideoLink.map((item) => {
                                    return (
                                      <iframe
                                        style={{
                                          width: "100%",
                                          height: "240px",
                                        }}
                                        controls={false}
                                        src={item.VideoLink}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen
                                      ></iframe>
                                    );
                                  })}
                                </OwlCarousel>
                              </>
                            ) : (
                              VideoLink.map((item) => {
                                return (
                                  <iframe
                                    style={{ width: "100%", height: "240px" }}
                                    controls={false}
                                    src={item.VideoLink}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                  ></iframe>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user ? (
        <Link
          to="/second-page"
          state={{ Category: dataSource[0]?.CategoryName }}
        >
          <FaArrowCircleRight
            style={{
              position: "fixed",
              right: "2%",
              bottom: "36%",
              color: "#ff7d26",
              fontSize: "80px",
              zIndex: "100",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "50%",
            }}
          />
        </Link>
      ) : (
        <FaArrowCircleRight
          style={{
            position: "fixed",
            right: "2%",
            bottom: "36%",
            color: "#ff7d26",
            fontSize: "60px",
            zIndex: "100",
            backgroundColor: "white",
            padding: "4px",
            borderRadius: "50%",
          }}
          onClick={() => setShow(true)}
        />
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 style={{ textAlign: "center", color: "#ff7d26" }}>
            NEED TO REGISTER/LOGIN
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="herp-0">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "6% 0% 0% 0%",
              }}
            >
              <button
                style={{
                  padding: "2% 4%",
                  backgroundColor: "rgb(0, 96, 170)",
                  color: "white",
                }}
              >
                <a
                  href="/register"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  REGISTER
                </a>
              </button>
              <button
                style={{
                  padding: "2% 6%",
                  backgroundColor: "rgb(0, 96, 170)",
                  color: "white",
                }}
              >
                <a
                  href="/login"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  LOGIN
                </a>
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;
