// import Navbar from "./Navbar";
import React, { useState, useEffect } from "react";
import { MdPageview } from "react-icons/md";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import axios from "axios";
// import "./Listings.css";
// import ReactPaginate from "react-paginate";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaImages } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

import "./Directory.css";
import ReactPaginate from "react-paginate";
import { AiFillDelete } from "react-icons/ai";

function Add_Pincode() {
  const [dataSource, setdataSource] = useState([]);
  const formdata = new FormData();

  useEffect(() => {
    AllPartners();
  }, []);

  const AllPartners = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getallpincode");
    if (res.status === 200) {
      // console.log(res);
      setdataSource(res.data.pincode);
    }
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = dataSource.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setdataSource([...dataSource]);
    }
  };

  const [category, setcategory] = useState();
  const [CategoryImage, setCategoryImage] = useState();
  const [show, setshow] = useState(false);

  const handleClose = () => {
    setshow(false);
  };

  const AddCategory = async (e) => {
    e.preventDefault();
    if (!category) {
      alert("Please Enter Pincode");
      return;
    }

    try {
      const config = {
        url: "/user/admin/addpincode",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { pincode: category },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          alert("Pincode Added");
          setshow(false);
          setcategory();
          setCategoryImage();
          AllPartners();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(dataSource.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [EditData, setEditData] = useState({});

  const [show1, setshow1] = useState(false);

  const handleClose1 = () => {
    setshow1(false);
  };

  const [category1, setcategory1] = useState("");
  const [CategoryImage1, setCategoryImage1] = useState("");

  //   const EditCategory = async (e) => {
  //     e.preventDefault();
  //     formdata.append("userId", EditData?._id);

  //     formdata.append("CategoryName", category1.toUpperCase());
  //     formdata.append("CategoryImage", CategoryImage1);

  //     try {
  //       const config = {
  //         url: "/user/editCategory",
  //         method: "post",
  //         baseURL: "https://maargdarshak.in/api",
  //         hearder: { "content-type": "application/json" },

  //         data: formdata,
  //       };
  //       await axios(config).then(function (res) {
  //         if (res.status === 200) {
  //           // console.log(res.data);
  //           alert("Category Updated");
  //           setshow1(false);
  //           setcategory1();
  //           setCategoryImage1();
  //           AllPartners();
  //         } else {
  //           alert("Something Wrong");
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error.response);
  //     }
  //   };

  // useEffect(() => {
  //   // Define the 'otpless' function
  //   window.otpless = () => {
  //     // Retrieve the user's details after successful login
  //     let otplessUser = { waName: "sachin", waNumber: 9535225600 };

  //     const waName = "sachin";
  //     const waNumber = 9535225600;

  //     // Handle the signup/signin process
  //     // ...
  //   };
  // }, []);

  const DeleteCategory = async (Id) => {
    try {
      const config = {
        url: "/user/admin/deletepincode",
        method: "post",
        baseURL: "https://maargdarshak.in/api",
        hearder: { "content-type": "application/json" },

        data: { id: Id },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          // console.log(res.data);
          AllPartners();
          // window.location.reload();
          // handleFilter();
          alert("Pincode Deleted");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div style={{ backgroundColor: "#f3f3f9", paddingBottom: "100px" }}>
      {/* <Navbar /> */}
      <div style={{ padding: "1% 2%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px 0px 20px",
          }}
        >
          <h6
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            PINCODE
          </h6>
          <button
            style={{
              backgroundColor: "#ff7d26",
              color: "white",
              fontWeight: "600",
              fontSize: "14px",
              padding: "10px 10px",
              borderRadius: "20px",
            }}
            onClick={() => setshow(true)}
          >
            +ADD PINCODE
          </button>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="EloetableOrder">
              <div class="card-body">
                <div class="row" style={{ padding: "10px 20px" }}>
                  <div class="col-sm-4">
                    <div class="search-box  d-inline-block">
                      <div
                        class="position-relative"
                        style={{ display: "flex" }}
                      >
                        <input
                          id="search-bar-0"
                          type="text"
                          aria-labelledby="search-bar-0-label"
                          class="form-control "
                          placeholder="Search Pincode"
                          onChange={handleFilter}
                          style={{
                            borderRadius: "50px",
                            border: "1px solid #ff7d26",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div class="col-sm-8">
                    <div class="text-sm-end">
                      {" "}
                      <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#1d6f42",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                          margin: "0px 10px 0px 0px",
                        }}
                        onClick={() => {
                          setEnterfilenameModal(true);
                        }}
                      >
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          <AiFillFileWord />
                        </span>{" "}
                        EXPORT TO EXCEL
                      </button>
                      <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#ff7d26",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                        }}
                        // onClick={() => {
                        //   setShow1(true);
                        // }}
                      >
                        {" "}
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          +
                        </span>{" "}
                        CATEGORY
                      </button>
                    </div>
                  </div> */}
                </div>
                <div class="table-responsive">
                  <div class="react-bootstrap-table">
                    <table
                      class="table table align-middle table-nowrap"
                      style={{ width: "96%", margin: "2%" }}
                    >
                      <thead
                        style={{ backgroundColor: "#ff7d26", color: "white" }}
                      >
                        <tr style={{ padding: "10px", textAlign: "center" }}>
                          <th
                            tabindex="0"
                            aria-label="Phone / Email sortable"
                            class="sortable"
                          >
                            SL NO{" "}
                            <span class="order">
                              <span class="dropdown">
                                <span class="caret"></span>
                              </span>
                              <span class="dropup">
                                <span class="caret"></span>
                              </span>
                            </span>
                          </th>

                          <th>PINCODE</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {search.length > 0
                          ? tableFilter
                              .slice(pagesVisited, pagesVisited + usersPerPage)
                              .map((item, index) => {
                                return (
                                  <tr
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{item.pincode}</td>
                                    <td style={{ fontSize: "20px" }}>
                                      {/* <BiEdit
                                    style={{ color: "#ff7d26" }}
                                    onClick={() => {
                                      setshow1(true);
                                      setEditData(item);
                                    }}
                                  />
                                  / */}
                                      <AiFillDelete
                                        style={{ color: "red" }}
                                        onClick={() => DeleteCategory(item._id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                          : dataSource
                              .slice(pagesVisited, pagesVisited + usersPerPage)
                              .map((item, index) => {
                                return (
                                  <tr
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <td>{index + 1}</td>
                                    <td>{item.pincode}</td>
                                    <td style={{ fontSize: "20px" }}>
                                      {/* <BiEdit
                                    style={{ color: "#ff7d26" }}
                                    onClick={() => {
                                      setshow1(true);
                                      setEditData(item);
                                    }}
                                  />
                                  / */}
                                      <AiFillDelete
                                        style={{ color: "red" }}
                                        onClick={() => DeleteCategory(item._id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  animation={true}
                  style={{ marginTop: "5%" }}
                >
                  <Modal.Header></Modal.Header>
                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <label
                      style={{
                        textAlign: "left",
                        paddingBottom: "10px",
                        fontSize: "22px",
                        color: "#ff7d26",
                        fontWeight: "500",
                      }}
                    >
                      Enter Pincode{" "}
                    </label>
                    <br></br>
                    <input
                      type="text"
                      maxLength={6}
                      required
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Pincode here..."
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={category}
                      onChange={(e) => {
                        setcategory(e.target.value);
                      }}
                    />
                    {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                  </div>
                  {/* <div class="add-list-media-wrap">
                    <div class="listsearch-input-item fl-wrap">
                      <div class="fuzone">
                        <form>
                          <div class="fu-text">
                            <span>
                              {!CategoryImage ? (
                                <FaImages
                                  style={{ fontSize: "70px", color: "#ffcc33" }}
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(CategoryImage)}
                                  alt="Thumb"
                                  style={{
                                    width: "25%",
                                    height: "76px",
                                    paddingTop: "1%",
                                    paddingBottom: "1%",
                                  }}
                                ></img>
                              )}
                              Click here or drop files to upload
                            </span>
                            <div class="photoUpload-files fl-wrap"></div>
                          </div>
                          <input
                            type="file"
                            name="file"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) =>
                              setCategoryImage(e.target.files[0])
                            }
                          />
                        </form>
                      </div>
                    </div>
                  </div> */}
                  <Modal.Footer
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ffcc33",
                        border: "none",
                        width: "120px",
                      }}
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>

                    <Button
                      style={{
                        backgroundColor: "#ff7d26",
                        border: "none",
                        width: "180px",
                      }}
                      onClick={AddCategory}
                    >
                      ADD PINCODE
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={show1}
                  onHide={handleClose1}
                  animation={true}
                  style={{ marginTop: "5%" }}
                >
                  <Modal.Header></Modal.Header>
                  <div class="col-md-12" style={{ margin: "2% 4%" }}>
                    <label
                      style={{
                        textAlign: "left",
                        paddingBottom: "10px",
                        fontSize: "22px",
                        color: "#ff7d26",
                        fontWeight: "500",
                      }}
                    >
                      Update PINCODE{" "}
                    </label>
                    <br></br>
                    <input
                      type="number"
                      placeholder={EditData?.pincode}
                      style={{
                        padding: "10px",
                        width: "90%",
                        border: "1px solid #ff7d26",
                      }}
                      value={category1}
                      onChange={(e) => {
                        setcategory1(e.target.value);
                      }}
                    />
                    {/* <button
              style={{
                padding: "10px",
                color: "white",
                backgroundColor: "#ff7d26",
                border: "none",
              }}
              onClick={addcategory}
            >
              Add Category
            </button> */}
                  </div>
                  {/* <div class="add-list-media-wrap">
                    <div class="listsearch-input-item fl-wrap">
                      <div class="fuzone">
                        <form>
                          <div class="fu-text">
                            <span>
                              {!CategoryImage1 ? (
                                <FaImages
                                  style={{ fontSize: "70px", color: "#ffcc33" }}
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(CategoryImage1)}
                                  alt="Thumb"
                                  style={{
                                    width: "25%",
                                    height: "76px",
                                    paddingTop: "1%",
                                    paddingBottom: "1%",
                                  }}
                                ></img>
                              )}
                              Click here or drop files to upload
                            </span>
                            <div class="photoUpload-files fl-wrap"></div>
                          </div>
                          <input
                            type="file"
                            name="file"
                            id="upload1"
                            accept="image/*"
                            onChange={(e) =>
                              setCategoryImage1(e.target.files[0])
                            }
                          />
                        </form>
                      </div>
                    </div>
                  </div> */}
                  <Modal.Footer
                    style={{
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#ffcc33",
                        border: "none",
                        width: "120px",
                      }}
                      onClick={handleClose1}
                    >
                      CANCEL
                    </Button>

                    <Button
                      style={{
                        backgroundColor: "#ff7d26",
                        border: "none",
                        width: "180px",
                      }}
                      //   onClick={EditCategory}
                    >
                      UPDATE PINCODE
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
}

export default Add_Pincode;
