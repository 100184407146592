import React, { useEffect, useState } from "react";
import "./navbar.css";
import { BsFillTelephonePlusFill, BsPencilFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { HiOutlineLightBulb } from "react-icons/hi";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsFillChatFill } from "react-icons/bs";

function AdminHeader() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const [navigation, setnavigation] = useState("relative");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setnavigation("fixed top-0 left-0 z-50")
        : setnavigation("relative");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [Logoutdiv, setLogoutdiv] = useState(false);

  return (
    <>
      <div className="header-0">
        <nav className="navigation">
          <a
            href="/Admindashboard"
            className="brand-name"
            style={{ width: "25%", textDecoration: "none" }}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/MaargdarshakLogo.jpeg"
                // className="roy"
                style={{
                  width: "90px",
                  height: "90px",
                  marginTop: "5px",
                  padding: " 13px",
                }}
              />
              <div>
                <p
                  className=""
                  style={{
                    color: "black",
                    marginTop: "16px",
                    fontSize: "22px",
                  }}
                >
                  <span style={{ color: "#ffa300" }}>Maa</span>
                  <span style={{ color: "#78be04" }}>rg</span>
                  <span style={{ color: "#00b2d6" }}>dar</span>
                  <span style={{ color: " #0060aa" }}>sh</span>
                  <span style={{ color: " #7f3e98" }}>ak</span>
                </p>
                <p
                  style={{
                    color: "#0060aa",
                    fontSize: "15px",
                    marginTop: "0px",
                    marginLeft: "6px",
                  }}
                >
                  Choose a right path ahead...
                </p>
              </div>
            </div>
          </a>

          {/* <a >
                <p
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       Choose a right path Ahead
      </p>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Skills</MenuItem>
        <MenuItem onClick={handleClose}>Training</MenuItem>
        <MenuItem onClick={handleClose}>Employment</MenuItem>
        <MenuItem onClick={handleClose}>Interest</MenuItem>
      </Menu>
                </a> */}

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {/* icon from Heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }
          >
            {admin ? (
              <div
                style={{ margin: "0px 10px 15px 0px" }}
                onClick={() => setLogoutdiv(!Logoutdiv)}
              >
                <img
                  src="https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png"
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  onClick={() => {
                    sessionStorage.removeItem("admin");
                    window.location.assign("/admin");
                  }}
                ></img>

                <h6
                  style={{
                    position: "absolute",
                    top: "69%",
                    color: "#ff7d26",
                  }}
                  onClick={() => {
                    sessionStorage.removeItem("admin");
                    window.location.assign("/admin");
                  }}
                >
                  Logout
                </h6>
              </div>
            ) : null}
          </div>
        </nav>
      </div>
    </>
  );
}

export default AdminHeader;
