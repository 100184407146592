import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import NationlNews from "./NationlNews";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import { useLocation } from "react-router-dom";

function SecondPages() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  let location = useLocation();

  const Category1 = location.state;
  console.log(Category1);
  const responsive4 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [Category, setCategory] = useState([]);
  const [SubCategory, setSubCategory] = useState([]);

  const [Links, setLinks] = useState([]);

  useEffect(() => {
    AllCategory();
    AllSubCAtegory();
    AllLinks();
    if (!user) {
      window.location.assign("/login");
    }
  }, []);

  const AllCategory = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCategory");
    if (res.status === 200) {
      setCategory(res.data.CategoryInfo);
    }
  };

  const AllSubCAtegory = async () => {
    let res = await axios.get("https://maargdarshak.in/api/user/getCategory1");
    if (res.status === 200) {
      setSubCategory(res.data.Category1Info);
    }
  };

  const AllLinks = async () => {
    let res = await axios.get(
      "https://maargdarshak.in/api/user/getSubCategory"
    );
    if (res.status === 200) {
      setLinks(res.data.SubCategoryInfo);
    }
  };

  const RedirectToNextPage = (Link) => {
    alert("You will be redirect to a External Website ");
    window.location.assign(Link);
  };

  const [search, setSearch] = useState("");
  const [tableFilter, settableFilter] = useState([]);

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      var filterTable = Links.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      settableFilter([...filterTable]);
    } else {
      setSearch(e.target.value);
      setLinks([...Links]);
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      setLinks(tableFilter);
    } else {
      AllLinks();
    }
  }, [search]);

  return (
    <>
      <div class="row" style={{ padding: "10px 20px" }}>
        <div class="col-sm-4">
          {/* <div class="search-box  d-inline-block">
            <div class="position-relative" style={{ display: "flex" }}>
              <input
                id="search-bar-0"
                type="text"
                aria-labelledby="search-bar-0-label"
                class="form-control "
                placeholder="Search Users"
                onChange={handleFilter}
                style={{
                  borderRadius: "50px",
                  border: "1px solid #ff7d26",
                }}
              />
            </div>
          </div> */}
        </div>

        <div class="col-sm-8">
          <div class="text-sm-end">
            <div class="search-box  d-inline-block">
              <div class="position-relative" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  placeholder="Search here.."
                  onChange={handleFilter}
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #ff7d26",
                  }}
                />
              </div>
            </div>{" "}
            {/* <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#1d6f42",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                          margin: "0px 10px 0px 0px",
                        }}
                        onClick={() => {
                          setEnterfilenameModal(true);
                        }}
                      >
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          <AiFillFileWord />
                        </span>{" "}
                        EXPORT TO EXCEL
                      </button> */}
            {/* <button
                        style={{
                          padding: "2px 8px 7px 8px",
                          color: "white",
                          backgroundColor: "#ff7d26",
                          border: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                          borderRadius: "20px",
                        }}
                        // onClick={() => {
                        //   setShow1(true);
                        // }}
                      >
                        {" "}
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                          +
                        </span>{" "}
                        CATEGORY
                      </button> */}
          </div>
        </div>
      </div>
      <div className="diff  m-3">
        {/* <input
          type="text"
          placeholder="Search here...."
          // onChange={(e) => setSearch(e.target.value)}
          style={{
            width: "40%",
            padding: "1%",
            fontSize: "20px",
            marginBottom: "1%",
            borderRadius: "20px",
            border: "1px solid #ff7d26",
          }}
        ></input> */}
        <a href={user ? "/" : "/login"}>
          <FaArrowCircleLeft
            style={{
              position: "fixed",
              left: "1%",
              bottom: "30%",
              color: "#ff7d26",
              fontSize: "80px",
              zIndex: "100",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "50%",
            }}
          />
        </a>
        {/* <div className="ne-diif">
          {dataSource.map((item) => {
            return (
              <div className="" id={item.CategoryName}>
                <h5 className="pb-1 pt-3" style={{ color: "#ffa300" }}>
                  {dataSource1.filter(
                    (data) => data.CategoryName == item.CategoryName
                  ).length < 1
                    ? null
                    : item.CategoryName}
                </h5>
                <div className="wraper-0  pb-2">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={7}
                    autoplay={true}
                    dots={false}
                    nav={false}
                    items={2}
                    responsive={responsive}
                  >
                    {dataSource1
                      .filter((data) => data.CategoryName == item.CategoryName)
                      .map((item) => {
                        return (
                          <div class="item">
                            <h5
                              className="pb-2 pt-3"
                              style={{ color: "#ffa300", height: "70px" }}
                            >
                              {item.Title}
                            </h5>
                            <a
                              href={
                                user
                                  ? item.SubCategoryLink
                                  : "https://maargdarshak.org/login"
                              }
                              style={{ color: "black" }}
                            >
                              <div className="list-3">
                                <div className="list-30">
                                  <div className="">
                                    <figcaption>
                                      <img
                                        src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                        alt="free"
                                        style={{
                                          width: "100%",
                                          height: "151px",
                                        }}
                                      />
                                    </figcaption>
                                  </div>
                                  <div className="entry-title">
                                    <h5 style={{ height: "50px" }}>
                                      {item.SubTitle}
                                    </h5>
                                    <p>{item.Description.slice(0, 300)}</p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div>
            );
          })}
       
        </div> */}
        <div className=" ne-diif">
          {Category?.filter(
            (data1) => Category1.Category == data1.CategoryName
          ).map((data) => {
            return (
              <>
                {Links.filter(
                  (data2) => data2.CategoryName == data.CategoryName
                ).length < 1 ? null : (
                  <>
                    <div style={{ display: "flex", paddingBottom: "1%" }}>
                      {" "}
                      <img
                        src={`https://maargdarshak.in/CategoryImage/${data.CategoryImage}`}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                          border: "2px solid #ff7d26",
                        }}
                      ></img>{" "}
                      <h3 style={{ color: "#ff7d26", padding: "0% 1%" }}>
                        {data.CategoryName}
                      </h3>
                    </div>
                  </>
                )}

                <div className="row">
                  {SubCategory.filter(
                    (data1) => data.CategoryName == data1.CategoryName
                  ).map((item) => {
                    return (
                      <div className="col-lg-4">
                        <h5
                          style={{
                            color: "#ff7d26",
                            padding: "2% 0%",
                            height: "15%",
                          }}
                        >
                          {Links.filter(
                            (data2) =>
                              data2.SubCategoryName == item.SubcategoryName
                          ).length < 1
                            ? null
                            : item.SubcategoryName}
                        </h5>
                        {/* <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={7}
                        autoplay={true}
                        dots={false}
                        nav={false}
                        items={2}
                        responsive={responsive}
                      > */}

                        <Carousel
                          // swipeable={false}
                          // draggable={false}
                          //showDots={true}
                          responsive={responsive4}
                          // ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          // keyBoardControl={true}
                          // customTransition="all .5"
                          transitionDuration={2000}
                          // containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          // deviceType={this.props.deviceType}
                          // dotListClass="custom-dot-list-style"
                          //itemClass="carousel-item-padding-40-px"
                        >
                          {Links.filter(
                            (data) =>
                              data.SubCategoryName == item.SubcategoryName
                          ).map((item) => {
                            return (
                              <div
                                className="col-lg-12"
                                style={{ padding: "1% 1%" }}
                                onClick={() =>
                                  RedirectToNextPage(item.SubCategoryLink)
                                }
                              >
                                <div
                                  className="list-3"
                                  style={{ height: "320px" }}
                                >
                                  <div className="list-30">
                                    <div className="">
                                      <figcaption>
                                        <img
                                          src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                          alt="free"
                                          style={{
                                            width: "100%",
                                            height: "151px",
                                            borderRadius: "20px",
                                          }}
                                        />
                                      </figcaption>
                                    </div>
                                    <div className="entry-title">
                                      <h5
                                        style={{
                                          height: "50px",
                                          color: "black",
                                        }}
                                      >
                                        {item.SubTitle}
                                      </h5>
                                      <p
                                        style={{
                                          color: "black",
                                        }}
                                      >
                                        {item.Description.slice(0, 300)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>

                        {/* </OwlCarousel> */}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
        <div className=" ne-diif">
          {Category?.filter(
            (data1) => Category1.Category != data1.CategoryName
          ).map((data) => {
            return (
              <>
                {Links.filter(
                  (data2) => data2.CategoryName == data.CategoryName
                ).length < 1 ? null : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: "1%",
                        paddingTop: "2%",
                      }}
                    >
                      {" "}
                      <img
                        src={`https://maargdarshak.in/CategoryImage/${data.CategoryImage}`}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                          border: "2px solid #ff7d26",
                        }}
                      ></img>{" "}
                      <h3 style={{ color: "#ff7d26", padding: "0% 1%" }}>
                        {data.CategoryName}
                      </h3>
                    </div>
                  </>
                )}
                <div className="row">
                  {SubCategory.filter(
                    (data1) => data.CategoryName == data1.CategoryName
                  ).map((item) => {
                    return (
                      <div className="col-lg-4">
                        <h5
                          style={{
                            color: "#ff7d26",
                            padding: "2% 0%",
                            marginTop: "2%",
                            height: "15%",
                          }}
                        >
                          {Links.filter(
                            (data2) =>
                              data2.SubCategoryName == item.SubcategoryName
                          ).length < 1
                            ? null
                            : item.SubcategoryName}
                        </h5>
                        {/* <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={7}
                        autoplay={true}
                        dots={false}
                        nav={false}
                        items={2}
                        responsive={responsive}
                      > */}

                        <Carousel
                          // swipeable={false}
                          // draggable={false}
                          //showDots={true}
                          responsive={responsive4}
                          // ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={3000}
                          // keyBoardControl={true}
                          // customTransition="all .5"
                          transitionDuration={2000}
                          // containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          // deviceType={this.props.deviceType}
                          // dotListClass="custom-dot-list-style"
                          //itemClass="carousel-item-padding-40-px"
                        >
                          {Links.filter(
                            (data) =>
                              data.SubCategoryName == item.SubcategoryName
                          ).map((item) => {
                            return (
                              <div
                                className="col-lg-12"
                                style={{ padding: "1% 1%" }}
                                onClick={() =>
                                  RedirectToNextPage(item.SubCategoryLink)
                                }
                              >
                                <div
                                  className="list-3"
                                  style={{ height: "320px" }}
                                >
                                  <div className="list-30">
                                    <div className="">
                                      <figcaption>
                                        <img
                                          src={`https://maargdarshak.in/SubCategoryImage/${item.SubCategoryImage}`}
                                          alt="free"
                                          style={{
                                            width: "100%",
                                            height: "151px",
                                            borderRadius: "20px",
                                          }}
                                        />
                                      </figcaption>
                                    </div>
                                    <div className="entry-title">
                                      <h5
                                        style={{
                                          height: "50px",
                                          color: "black",
                                        }}
                                      >
                                        {item.SubTitle}
                                      </h5>
                                      <p
                                        style={{
                                          color: "black",
                                        }}
                                      >
                                        {item.Description.slice(0, 300)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>

                        {/* </OwlCarousel> */}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SecondPages;

{
  /* <div className="second-her0 container-fluid mb-3">
<div className="row">
  <div className="col-lg-12">
    <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
      Skill India,Digital India
    </h5>
    <div className="left-marg">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        responsive={responsive}
        nav={false}
        autoplay={true}
        dots={false}
      >
        <div class="item">
          <a
            href="https://www.pmkvyofficial.org/trainingcenter"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/NCSBanner.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>
                    Pradhan Mantri Kaushal Vikas Yojana (PMKVY) Training
                    Centers List
                  </h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://www.skilldevelopment.gov.in/en/schemes-initiatives/schemes-initiatives-through-nsdc/pradhan-mantri-kaushal-vikas-yojana-pmkvy"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/NCSBanner.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Skill Development</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://www.nsdcindia.org/"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/NCSBanner.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>National Skill Development Corporation</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://data.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-1.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>National Skills Network</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://nsdcindia.org/national-portal-trainers-and-assessors"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-2.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Trainers and Assessors</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://nsdcindia.org/national-portal-trainers-and-assessors"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-3.png"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Telecom Sector Skill Council</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://www.csdcindia.org/"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-4.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>
                    Construction Skill Development Council Of India
                  </h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            href="https://www.csdcindia.org/"
            style={{ color: "black" }}
          >
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-5.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Textile Sector Skill Council</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://www.asdc.org.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-6.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Automotive Skills Development Council</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://www.asdc.org.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Nskill-7.png"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>EX-SERVICEMEN WELFARE</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </OwlCarousel>
    </div>
  </div>
  {/* <div className='right-marg'>
<div className='col-md-4'>

</div>
</div> 
</div>
</div>


<div className="second-her0 container-fluid mb-3">
<div className="row">
  <div className="col-lg-12">
    <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
      Employment Enablement
    </h5>
    <div className="left-marg">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        responsive={responsive}
        nav={false}
        autoplay={true}
        dots={false}
      >
        <div class="item">
          <a href="https://www.ncs.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/NCS-Banner3.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>National Career Service</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://igotkarmayogi.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi2.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Karmayogi Bharat</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://csc.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/pub.jpeg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Common Service center schemes</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=2&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/re-1.webp"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Government  of India - Jobs</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://disabilityaffairs.gov.in/content/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi3.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Department of Empowerment of Persons with Disabilities</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://disabilityaffairs.gov.in/content/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi4.png"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Ministry of Micro, Small & Medium Enterprises</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>

      </OwlCarousel>
    </div>
  </div>
  {/* <div className='right-marg'>
<div className='col-md-4'>

</div>
</div> 
</div>
</div>

<div className="second-her0 container-fluid mb-3">
<div className="row">
  <div className="col-lg-12">
    <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
   Policies
    </h5>
    <div className="left-marg">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        responsive={responsive}
        nav={false}
        autoplay={true}
        dots={false}
      >
        <div class="item">
          <a href="https://niti.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/niti.jpeg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Nitiaayog</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
       

      </OwlCarousel>
    </div>
  </div>
  {/* <div className='right-marg'>
<div className='col-md-4'>

</div>
</div> 
</div>
</div>

<div className="second-her0 container-fluid mb-3">
<div className="row">
  <div className="col-lg-12">
    <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
 Education & Educational Institutions
    </h5>
    <div className="left-marg">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        responsive={responsive}
        nav={false}
        autoplay={true}
        dots={false}
      >
        <div class="item">
          <a href="https://www.mygov.in/ppc-2023/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi5.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Pariksha Pe Charcha</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://scholarships.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi6.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>National Scholarship Portal</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://www.education.gov.in/en/scholarships-education-loan" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi7.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Department of Higher Education</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://samagra.education.gov.in/" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi8.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Samagra Shiksha</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://www.education.gov.in/en/institutions" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi9.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Education Institutions</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://src.udiseplus.gov.in/newSearchSchool/searchSchool" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi9.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Search Schools</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
       

      </OwlCarousel>
    </div>
  </div>
  {/* <div className='right-marg'>
<div className='col-md-4'>

</div>
</div> 
</div>
</div>

<div className="second-her0 container-fluid mb-3">
<div className="row">
  <div className="col-lg-12">
    <h5 className="pb-2 pt-3" style={{ color: "#ffa300" }}>
Scholarship,Coaching and counselling
    </h5>
    <div className="left-marg">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        responsive={responsive}
        nav={false}
        autoplay={true}
        dots={false}
      >
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=67&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Apprenticeships and Training</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=68&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Education and Training Grants</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
       
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=68&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Education and Training Grants</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=122&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Resources For Students</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=122&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Resources For Students</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=66&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Scholarships and Student Finance</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="item">
          <a href="https://services.india.gov.in/service/listing?cat_id=68&ln=en" style={{ color: "black" }}>
            <div className="list-3">
              <div className="list-30">
                <div className="">
                  <figcaption>
                    <img
                      src="/image/Modi10.jpg"
                      alt="free"
                      style={{ width: "100%", height: "151px" }}
                    />
                  </figcaption>
                </div>
                <div className="entry-title">
                  <h5>Scholarships and Student Finance</h5>
                  <p>
                    "Traditional Knowledge Digital Library (TKDL) is an
                    online repository on knowledge available in public
                    domain about traditional knowledge from the existing
                    literature related to Ayurveda, Unani and Siddha in
                    digitized format,
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>

      </OwlCarousel>
    </div>
  </div>
 
</div>
</div> */
}
